@import "./styles.scss";

._updateUser-grand-tittle {
    @extend .standard-format-text;
};
._updateUser-subtittle {
    font-size: 14px;
    @extend .standard-format-subtittles;
};
._updateUser-box-select-new {
    margin: auto;
    width: 48%;
    text-align: center;
};
._updateUser-tittle-form{
    font-size: 14px;
    font-weight: 600;
    @extend .standard-format-subtittles;
};
._updateUser-container {
    width: 66%;
    margin: auto;
}
._updateUser-container-form {
    background-color: white;
    padding: 21px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 0 0 5px 0 rgba(189, 189, 189, 0.5);
};
._updateUser-container-form label,
    ._updateUser-custom-label,
        ._updateUser-container-search label {

    position: relative;
    @extend ._updateUser-col-form;
    @extend ._updateUser-subtittle;
    margin-bottom: 12px;
};
._updateUser-container-form label input, 
    ._updateUser-container-form div input, 
        ._updateUser-container-search label input {

    @extend ._updateUser-box-input-form;
    @extend .simple-text;
};
._updateUser-container-form label input:invalid {
    border: solid 1px var(--red-error) !important;
}
._updateUser-container-form label input:focus {
    border: solid 1px var(--blue-subtittles);
};
._updateUser-col-form {
    display: flex;
    flex-direction: column;
    width: 48%;
};
._updateUser-box-input-form {
    height: 40px;
    padding: 10.3px 3px 10.7px 15px;
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    background-color: white;
};
._updateUser-buttons-group {
    width: 100%;
    display: flex;
    gap: 4%;
    margin-top: 5vh;
};
._updateUser-error-text {
    position: absolute;
    bottom: -14px;
    font-size: 10px;
    font-weight: 600;
    line-height: 1.4;
    color: var(--red-error);
};

@mixin dynamic-buttons($backColor, $borderColor) {
    background-color: $backColor;
    border: solid 1px $borderColor;
}
._updateUser-general-button-box {
    width: 22%;
    height: 40px;
    padding: 9.7px 12px 11.3px 11px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
};
._updateUser-save-button {
    @extend ._updateUser-general-button-box;
    @include dynamic-buttons( var(--blue-subtittles), #dee1e5);
    color: white;
};
._updateUser-cancel-button {
    @extend ._updateUser-general-button-box;
    @include dynamic-buttons( white, var(--blue-subtittles));
    color: var(--blue-subtittles);
};
._updateUser-cancel-button:disabled {
    border: solid 1px #dee1e5;
};
._updateUser-container-search {
    display: flex;
    align-items: center;
    position: relative;
    & ._updateUser-col-form {
        width: 100%;
    };
};
._updateUser-search-button {
    margin: 9px 0px 0px 12px;
    width: 92px;
    @extend ._updateUser-save-button;
};
._updateUser-search-error {
    position: absolute;
    top: -40px;
    height: 34px;
    width: 100%;
    text-align: center;
    padding: 5px 0px 8px;
    background-color: var(--red-error-light);
    border-radius: 3px;
    border: solid 1px var(--red-error-light);
    font-size: 14px;
    color: var(--red-error);
};
._updateUser-box-table {
    width: 78.7%;
};
._updateUser-box-table tr th {
    height: 57px;
    background-color: rgba(0, 64, 168, 0.05);
    font-size: 12px;
    font-weight: bold;
    padding-left: 20px;
};
._updateUser-box-table tr td {
    padding-left: 20px;
    border-radius: 4px;
    height: 49px;
    background-color: #fff;
    color: #5c5c5c;
    font-size: 12px;
};
._updateUser-remove-button {
    width: 77px;
    height: 31px;
    padding: 4px 10px;
    @extend ._updateUser-save-button;
}
.select-component-staff {
    @extend ._updateUser-tittle-form;
    cursor: pointer;
  }