@import '../../../assets/icons.scss';

:root {
  --gray-tittles: #545454;
  --blue-subtittles: #002c76;
  --gray-text: #4a4a4a;
  --red-error: #d0031c;
  --red-error-light: #f8d7da;
}
.staff_ml-20 {
  margin-left: 20px;
}
.mt-grand {
  margin-top: 4vh;
}
.mb-34 {
  margin-bottom: 34px;
}
.separator-margin {
  margin: 0px 25px 0px 21px;
}
.mt-10 {
  margin-top: 8vh;
}
.mt-2 {
  margin-top: 2px !important;
}
.standard-format-text {
  font-weight: 600;
  text-align: center;
  color: var(--gray-tittles);
}
.simple-text {
  font-size: 14px;
  color: var(--gray-text);
}
.standard-format-subtittles {
  color: var(--blue-subtittles);
}
.InsertData_DNO{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5% 0 0 0;
}

.InsertData_DNO-tittle {
  width: 353px;
  margin: 0 109px 10px 111px;
  font-size: 22px;
  @extend .standard-format-text
}

.InsertData_DNO-sub-tittle {
  width: 305px;
  margin: 11px 0 18px 6px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0041a1;
  display: flex;

}
.info_data{
  margin: 0px 0px 0px 63px;;
  padding: 0;
  display: flex;
}
.info_data_container{
  width: 100%;
  margin: 0px 104px 0px 0px;;
  padding: 0 0 0 0;
}

.InsertData_DNO-sub-box{
  margin: 10px 0px 0px 0px;
  padding: 0;
}
.input_data_plant {
  display: none;
}

.button-upload {
  width: 160px;
  height: 20px;
  background-color: #fff;
  color: #002c76;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.Planta_DNO{
  width: 30%;
  height: 75%;
  background-color: #fff;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.documentUploaded{
  margin: 0 0 0 20px;
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #4a4a4a;
}

.insert-data-dno-text-icon {
  background: no-repeat $DNOtextIcon;
  background-size: contain;
  height: 14px;
  width: 24px;
  align-self: center;
  display: inline-block;
}
.insert-data-dno-document-icon{
  background: no-repeat $DocumetIcon;
  background-size: contain;
  height: 20px;
  width: 24px;
  align-self: center;
  display: inline-block;
  margin: 0 15px 0 0;
}

.insert-data-dno-button-icon {
  background: no-repeat $uploadButtonIcon;
  background-size: contain;
  height: 14px;
  width: 24px;
  align-self: center;
  display: inline-block;
}

.download-data-dno-button{
  width: 83%;
  background-size: contain;
  align-self: center;
  justify-content: space-between;
  display: inline-block;
  font-size: 16px;
  margin: 0% 0% 0% 19%;
}

.download-data-dno-button-icon {
  background: no-repeat  $uploadIcon;
  background-size: contain;
  height: 25px;
  width: 50px;
  align-content: center;
  display: inline-block;
  margin: -2% auto;
}
.download-button-file{
  width: 24%;
  height: 46px;
  border-radius: 21px;
  background-color: #002c76;
  font-size: 14px;
  color: white;
  display:flex;
  justify-content: space-between;
  cursor: pointer;
  margin: 3% 0% 0% 0%;
}

.insert-data-dno-document-xlxs-icon{
  background: no-repeat $xtlxIcon;
  background-size: contain;
  height: 20px;
  width: 20px;
  margin: 0px 0px 0px 0px;
}

.InsertData_DNO-sub-box {
    width: 573px;
    height: 90px;
    margin: 20px 0 0;
    padding: 38px 41px 28px 30px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(170, 192, 208, 0.5);
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between
  }

  .fileUploaded{
    width: 7%;
    height: 30px;
    margin: 0 0px 0px 0px;
    font-size: 16px;
    color: #4a4a4a;

  }
  .data_InfoFileUploaded{
    width: 100%;
    height: 21px;
    margin: 0px 0px 0px 20px;
    display: flex;
  }
  .data_InfoFileUploaded_container{
    font-size: 14px;
    margin: 0px 5px 0px 0px;
    color: #9b9b9b;
  }
  .data_InfoFileUploaded_Records{
    width: 100%;
    font-size: 14px;
    color: #9b9b9b;
  }


  .InsertData_DNO-Container{
    width: 454px;
    margin: 20px 1px 0 0;
    padding: 27px 0px 12px 0px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(170, 192, 208, 0.5);
    background-color: #fff;
  }

  .Line {
    width: 100%;
    height: 1px;
    margin: 20px 0 -30px;
    border: solid 1px #e3e3e3;
  }

  .InsertData_DNO-Container-buttons{
    width: 100%;
    height: 75px;
    margin: 8px 0px 0px 34px;
    padding: 0% 0% 0% 0%;
    display: flex;

  }

  .uploade-button{
    width: 124px;
    height: 33px;
    margin: 37px 60px 0 52px;
    padding:5px 30px 9px 30px;
    border-radius: 4px;
    background-color: #002c76;
    text-decoration-color: #e3e3e3;
    color: #ffff;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    cursor: pointer;
  }
  .imgButton{
    background: no-repeat $uploadButtonIconWhite;
    background-size: #ffff;
    height: 16px;
    width: 16px;
    margin: 1px 0px 0 0px;
    padding: 0 22px 0 0;
    color: #ffff;

  }

  .cancel-button{
    width: 124px;
    height: 33px;
    margin: 37px 0 0 0px;
    padding:3px 26px 8px 27px;;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    border: solid 1px #0040a8;
    background-color: #fff;
    color:#0040a8;

  }
._manage-files-row-table {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #4b4b4b;
}
._manage-files-col-1-table {
  background-color: #d7dce5;
  width: 60%;
  padding: 6px 19px;
}
._manage-files-col-2-table {
  background-color: #edf1f8;
  width: 20%;
  text-align: end;
  padding: 6px 19px;
}
._manage-files-table {
  margin-top: 19px;
}
.loading-spinner {
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
  margin-right: 1%;
}
.box-spinner {
  width: 20px;
  height: 20px;
}
.container-spinner {
  margin-bottom: 10px;
}
.cancel-button:disabled {
  border: none;
}
._selected-link {
  border-width: 0 0 1px;
  border-style: solid;
}

@media(max-width:1200px){
  .download-button-file{
    height: 36px;
  }
  .download-data-dno-button{
    font-size: 11px;

  }

  .download-data-dno-button-icon{
    height: 20px;
  }

}

.downloadCSV-select-time{
width: 100%;
height: 40px;
padding: 9px 17px 9px 13px;
border-radius: 3px;
border: solid 1px #d3d1d3;
background-color: #fff;
}

.downloadCSV-arrow-left {
  right: 5px;
}

.container_Warning{
  width: 80%;
  height: auto;
  margin-top: 10%;
  background: #FFE7E6;
  border: 1px solid #C94740;
  border-radius: 8px;
}

.container_text {
  width: 260px;
  margin: 10px 25px;
}

.container_message{
  width: auto;
  font-size: 12px;
  line-height: 17px;
  color: #000;
  line-height: -0.03;
}

.container_duplicate{
  margin-top: -2%;
}

.texto_list{
  color: #000;
  font-size: 14px;
  font-family: Roboto;
  padding-left: 5%;
}

ul {
  list-style-type: disc;
  font-size: 0.8em;
}

@media(max-width:1289px){
  .download-button-file{
    width: 36%;
    height: 46px;
    border-radius: 21px;
    background-color: #002c76;
    font-size: 14px;
    color: white;
    display:flex;
    justify-content: space-between;
    cursor: pointer;
    margin: 3% 0% 0% 0%;
  }

}