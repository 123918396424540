@import '../../../../assets/styles/fonts.css';

.hg-55 {
    height: 55vh;
}
.reserve-full-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: default;

}
.mb-18 {
    margin-bottom: 18px;
}
.mb-23 {
    margin-bottom: 23px;
}
.mb-6 {
    margin-bottom: 6px;
}
.row-form-reserve {
    display: flex;
    
}
.empty-space-1{
    width: 26px;
}
.width-34 {
    width: 34.5%;
}
.mb-9 {
    margin-bottom: 9px;
}
p {
    margin-bottom: 17px;
}
.title-reserve {
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #545454;
}
.alert-reserve {
    width: 401px;
    height: 34px;
    padding-block: 5px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}
.alert-approve {
    border: solid 1px #c3e6cb;
    background-color: #d4edda;
    color: #5d5d5d;
}
.alert-reject {
    border: solid 1px #f5c6cb;
    background-color: #f8d7da;
    color: #e4060d;
}
.form-reserve {
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 5%;
    background-color: #ffff;
}
.status-reserve {
    width: 62px;
    height: 17px;
    padding: 0px 5px 1px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}
.status-reserve-approve {
    border: solid 1px #a2d5af;
    background-color: #d4edda;
    color: #7db18a;
}
.status-reserve-replaced {
    border: solid 1px #f77d34;
    background-color: #fce9de;
    color: #f79155;
}
.status-reserve-reject {
    border: solid 1px #ef666a;
    background-color: #f8d7da;
    color: #ef666a;
}
.title-guarantee {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0041a1;
    margin-bottom: 19px;
}
.titles-form {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #002c76;
}
.input-form {
    height: 25px;
    padding: 3px 17px 3px 11px;
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    background-color: #eaecef;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.85);
}
.input-form-short {
    width: 130px;;
}
.input-form-long {
    width: 286px;;
}
.button-copy {
    width: 130px;
    border-radius: 4px;
    border: solid 1px #002c76;
    background-color: #002c76;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    outline: none;
}
.button-copy:focus {
    outline: none;
}

.text-replaced {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5c5c5c;
    margin-left: 10px;
}
.input-product-replaced {
    border: solid 1px #f8985e;
}
.box-rejected {
    width: 442px;
    height: 110px;
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    background-color: #eaecef;
    padding: 4px 17px 4px 11px;;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
}
