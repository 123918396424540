@import '../../../../assets/styles/fonts.css';

.fng-search-title {
    color: #545454;
    font-family: OpenSans-Semibold, serif;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 3%;
    margin-top: 5%;
}

.search-form-container {
    align-items: center;
    cursor: default;
    display: flex;
    flex-direction: column;
}

.search-form-input-title-container {
    height: 25px;
    width: 45%;
}

.search-form-input-title {
    color: #002c76;
    font-family: OpenSans-Regular, serif;
    font-size: 14px;
}

.search-form-input-container {
    display: flex;
    flex-direction: row;
    width: 45%;
}

.search-form-input {
    background-color: #fff;
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    font-family: OpenSans-Regular, serif;
    font-size: 16px;
    height: 42px;
    margin-right: 1%;
    padding-left: 2%;
    width: 70%;
}

.search-form-input-error {
    border: solid 1px #e4060d;
}

.search-query-btn {
    background-color: #002c76;
    border-radius: 4px;
    color: #fff;
    font-family: OpenSans-Semibold, serif;
    font-size: 14px;
    height: 42px;
    margin-left: 1%;
    width: 30%;
}

.search-query-btn-disabled {
    background-color: #eaeef2;
    border-radius: 4px;
    border: none;
    color: #d6d6d6;
    font-family: OpenSans-Semibold, serif;
    font-size: 14px;
    height: 42px;
    margin-left: 1%;
    width: 30%;
}

.search-fng-error {
    border-radius: 3px;
    border: solid 1px #f5c6cb;
    background-color: #f8d7da;
    color: #e4060d;
    font-family: OpenSans-Regular, serif;
    font-size: 14px;
    margin-bottom: 1%;
    padding: 7px 20px;
    text-align: center;
    width: 40%;
}
