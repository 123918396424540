.report-case-content-row {
    margin: 3% 0% 3% 0%;
}

.report-case-content-row-lonely {
    margin: 8% 0% 8% 0%;
}

.report-case-header {
    height: 10vh;
}

.report-case-section {
    height: 55vh;
    display: flex;
    justify-content: center;
}

.report-cases-box {
    padding:  1.5%;
    background-color: #fff;
    min-width: 45%;
    position: absolute;
}

.report-cases-box-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0041a1; 
    margin-bottom: 3%;
}

.report-cases-box-full {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.report-cases-box-full-lonely {
    justify-content: flex-start !important;
}


.report-cases-box-middle {
    display: flex;
    width: 48%;
}

.report-cases-box-label {
    align-items: flex-start;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #002c76;
    padding-bottom: 1%;
}

.report-cases-input {
    padding: 5.3px 31px 4.4px 11px;
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    background-color: #fff;
    width: 100%;
}

input#reportCasesNumber:placeholder-shown {
    background-image: none;
}

input#reportCasesDate:placeholder-shown {
    background-image: none;
}

input#reportCasesNumber::placeholder {
    padding-left: 0%;
    color: #bcbfc2;
    font-size: 12px;
}

input#reportCasesDate::placeholder {
    padding-left: 0%;
    color: #bcbfc2;
    font-size: 12px;
}

input#reportCasesDate:disabled {
    background-color: #f4f4f4;
    color: #d6d6d6;
}

.report-case-button {
    height: 20%;
    width: 24%;
    cursor: pointer !important;
    padding: 3px 10px 4px 10px;
    border-radius: 4px;
    background-color: #002c76;
}

.report-case-button-font {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.report-case-button-disabled {
    height: 26px;
    width: 32%;
    cursor: default !important;
    padding: 3px 31px 4px 30px;
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    background-color: #eaeef2;
}

.report-case-button-font-disabled {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #aeaeae;
}

.report-case-title {
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #545454;
}

.report-case-btn {
    padding-top: 10%;
}

.case-report-alert {
    width: 42%;
    padding: 7px 52px 8px 53px;
    border-radius: 3px;
    border: solid 1px #c3e6cb;
    background-color: #d4edda;
}

.case-report-text-alert {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5d5d5d;
}

.case-report-content-alert {
    display: flex;
    justify-content: center;
    padding-bottom: 2%;
}

.switch {
    position: relative;
    display: inline-block;
    width: 8%;
    height: 70%;
}
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 65%;
    width: 35%;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.2s;
  }
  
  input:checked + .slider {
    background-color: #7ad468;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #7ad468;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(114%);
    -ms-transform: translateX(114%);
    transform: translateX(114%);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

.case-report-alert-error {
    width: 42%;
    padding: 7px 52px 8px 53px;
    border-radius: 3px;
    border: solid 1px #f5c6cb;
    background-color: #f8d7da;
}

.case-report-text-alert-error {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e4060d;
}

.case-report-content-alert-error {
    display: flex;
    justify-content: center;
    padding-bottom: 2%;
}