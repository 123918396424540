/*
 * Leader css File
*/
.width-table {
    width: 27% !important;
}
.head-table {
    background-color: #d7dce5 !important;
    color: #002c76 !important;
    font-size: 14px !important;
}

.body-table {
    background-color: #002c76 !important;
    color: #f7fbff !important;
    font-size: 12px !important;
}
.spam-font {
    font-weight: 100 !important;
}
.th-text-row {
    width: 160px !important;
}
.th-number-row {
    width: 46px !important;
}      
.td-table {
    color: rgba(0, 0, 0, 0.85) !important;
    background-color: #ffffff !important;
    border: inset 0pt !important;
}
.options {
    color: #002c76 !important;
    cursor: pointer !important;
    font-size: 16px !important;
    font-weight: 600;
    padding: 0% 1% 0% 1%;
}
.button-style {
    width: 12%  !important;
    background-color: #002c76 !important;
    border-radius: 100px !important;
}
.p-value {
    padding-right: 11% !important;
    text-align: end !important;
}
.p-label {
    padding-left: 11% !important;
    text-align: start !important;
}
.table-header {
    width: 290px;
    margin: 0px 521px 0 524px;
    padding: 10px 30px 10px 30px;;
    background-color: #d7dce5;
}
.weight-head {
    font-size: 14px;
    color: #002c76;
    font-weight: 600;
}
.slim-head {
    font-size: 14px;
    color: #002c76;
    font-weight: 300 !important;
}
.table-body {
    display: grid;
    width: 290px;
    margin: 0px 521px 0 524px;
    padding: 10px 30px 10px 30px;;
    background-color: #ffffff;
}
.row-table {
    margin: 3% 0 3% 0;
}
.row-label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}
.button-marging {
    margin-top: 2.5%;
}
.arrow-icon{
    background: no-repeat url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='4px' height='6px' viewBox='0 0 4 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eArtboard%3c/title%3e%3cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cpolygon id='›' fill='%23FFFFFF' fill-rule='nonzero' points='1.60620117 5.57763672 3.57495117 2.91162109 3.57495117 2.78173828 1.60620117 0.115722656 0.464599609 0.115722656 2.15307617 2.85009766 0.464599609 5.57763672'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
    background-size: 80%;
    background-position-x: center;
    background-position-y: center;
    width: 10px;
    height: 10px;
}
.button-text-style{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.content-option-leader {
    display: flex;
    justify-content: center;
    color: #002c76 !important;
}

.modal-text {
    text-align: center;
    color: #545454;
    font-size: 1.125em;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 5%;
  }