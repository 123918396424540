input:placeholder-shown {
    display: initial !important;
}

.div-reason {
    display: flex;
}

.div-paused {
    margin: 0% 1% 1% 0%;
    padding: 0.1% 0.3%;
    border-radius: 4px;
    border: solid 1px #f77d34;
    background-color: #fce9de;
    font-size: 12px;
    color: #f79155;
    text-align: center;
    width: max-content;
    line-height: normal;
}

.div-reason-paused {
    text-align: center;
    padding: 0.2% 0.3%;
    font-size: 12px;
    line-height: normal;
    letter-spacing: normal;
    color: #5c5c5c;
}

/* .table {
    width: auto;
} */