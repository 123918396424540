@import '../../../../assets/styles/fonts.css';

.query-main-container {
    align-items: center;
    cursor: default;
    display: flex;
    flex-direction: column;
    margin-top: 3%;
}

.query-form-container {
    background-color: #fff;
    padding: 11px 22px 31px 18px;
    width: 50%;
}

.query-form-title {
    color: #0041a1;
    font-family: OpenSans-Semibold, serif;
    font-size: 24px;
    font-weight: bold;
}

.query-inputs-container {
    display: flex;
    flex-wrap: wrap;
}

.query-form-group {
    margin: 0 1% 4% 2%;
    width: 47%;
}

.query-form-input-title-container {
    height: 25px;
    width: 100%;
}

.query-form-input-title {
    color: #002c76;
    font-family: OpenSans-Regular, serif;
    font-size: 14px;
    width: 100%;
}

.query-form-input {
    background-color: #eaecef;
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    color: rgba(0, 0, 0, 0.85);
    font-family: OpenSans-Regular, serif;
    font-size: 14px;
    padding-left: 4%;
    width: 100%;
}

.query-form-num {
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    color: rgba(0, 0, 0, 0.85);
    font-family: OpenSans-Regular, serif;
    font-size: 14px;
    padding-left: 4%;
    width: 20%;
}

.query-form-input-active {
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    color: rgba(0, 0, 0, 0.85);
    font-family: OpenSans-Regular, serif;
    font-size: 14px;
    padding-left: 4%;
    width: 100%;
}

.query-form-input-select-active {
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    color: rgba(0, 0, 0, 0.85);
    font-family: OpenSans-Regular, serif;
    font-size: 14px;
    padding-left: 4%;
    width: 100%;
    height: 32%;
}
.query-form-input-error {
    border: solid 1px #e4060d;
}

.query-button-container {
    margin-top: 3%;
    text-align: center;
    width: 100%;
}

.query-request-reserve {
    background-color: #002c76;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-family: OpenSans-Semibold, serif;
    font-size: 14px;
    padding: 1% 4%;
}

.query-request-reserve-disabled {
    background-color: #eaeef2;
    border-radius: 4px;
    border: none;
    cursor: default;
    color: #d6d6d6;
    font-family: OpenSans-Semibold, serif;
    font-size: 14px;
    padding: 1% 4%;
}

.query-fng-error {
    border-radius: 3px;
    border: solid 1px #f5c6cb;
    background-color: #f8d7da;
    color: #e4060d;
    font-family: OpenSans-Regular, serif;
    font-size: 14px;
    margin-bottom: 3%;
    margin-left: 10%;
    padding: 7px 20px;
    text-align: center;
    width: 80%;
}

.buttonGroup{
    width: 82%;
    position: relative; left:70px; bottom: 26.5px; 
}
.toggle{
    height: 24.5px !important;
    font-family: OpenSans-Regular, serif;
    font-size: 12px !important; 
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    padding-bottom:18px !important;
    line-height: normal;
    letter-spacing: normal;
}


