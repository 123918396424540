@import '../../../assets/icons';

.dly-relative{
  position: relative;
  left: 79%;
}
.dly-absolute{
  position: absolute;
  top: 100px;
  left: 75%;
  display: flex;
  justify-content: space-evenly;
}

.container-button{
  display: flex;
  flex-direction: row;
  margin: 0% 0% -5% 0%;
}

.dly-mngmnt {
  background: no-repeat $scheduleIcon;
  width: fit-content;
  border-radius: 6px;
  border: solid 1px #0040a8;
  background-color: #fff;
  background-position-y: center;
  background-position-x: 19px;
  padding: 0 0 0 2.5%;
  text-align: center;
  cursor: pointer;
}

.dly-novedadesLider{
    width: fit-content;
    border-radius: 6px;
    border: solid 1px #0040a8;
    background-color: #fff;
    background-position-y: center;
    background-position-x: 19px;
    padding: 0 0 0 2%;
    margin: 0% 1% 0% 0%;
    text-align: center;
    display: flex;
    flex-direction: row;
    cursor: pointer;

}

.button-news{
  width: 30px;
  height: 30px;
  background: no-repeat $addIconBlue;
  margin: 5% -10% 0% 0%;
  padding: 0% 0% 0% 0%;
}

.dly-text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.2px;
    color: #0040a8;
    display: inline-block;
    padding: 16px 16px 16px 25px;
  }

