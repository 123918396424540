input:focus{
    outline: none;
}

input:placeholder-shown {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg fill='%23DADADA' fill-rule='nonzero'%3e%3cg%3e%3cg%3e%3cg%3e%3cpath d='M5.417 10.833C2.43 10.833 0 8.403 0 5.417 0 2.43 2.43 0 5.417 0c2.987 0 5.416 2.43 5.416 5.417 0 2.987-2.43 5.416-5.416 5.416zm0-9.48c-2.241 0-4.064 1.823-4.064 4.064 0 2.24 1.823 4.063 4.064 4.063 2.24 0 4.063-1.823 4.063-4.063 0-2.241-1.823-4.064-4.063-4.064z' transform='translate(-762.000000, -304.000000) translate(751.000000, 292.000000) translate(11.000000, 3.000000) translate(0.000000, 9.000000)'/%3e%3cpath d='M12.459 13c-.139 0-.278-.053-.383-.158L8.826 9.59c-.212-.211-.212-.554 0-.766.21-.211.553-.211.765 0l3.25 3.25c.212.212.212.555 0 .767-.105.105-.244.158-.382.158z' transform='translate(-762.000000, -304.000000) translate(751.000000, 292.000000) translate(11.000000, 3.000000) translate(0.000000, 9.000000)'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e ");
    background-repeat: no-repeat;
    background-position: 12px center;
    background-size: 15px;
    display: flex;
    align-items: center;
}

input::placeholder {
    padding-left: 10%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #dadada;
}

.font-tab-librances {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  text-align: center;
}

.color-enable-librances {
    color: #002c76;
    font-weight: 600;
}

.color-disabled-librances {
    color: #c0c3c6;
    font-weight: 100;
}

.tab-disabled-librances {
    width: 52%;
    height: 42px;
    padding: 12px 40px 11px;
    margin-right: 0%;
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0 -1px 4px 0px rgb(140 140 140 / 50%);
    background-color: #f7fbff;
}

.tab-enable-librances {
    width: 52%;
    height: 42px;
    padding: 12px 31px 11px 30px;
    margin-right: 0%;
    border-radius: 4px 4px 0 0;
    background-color: #d7dce5;
}

.tabs-content-librances {
    display: flex;
    width: 40%;
    justify-content: space-between;
}

.margin-tab-librances {
    margin-right: 3%;
}

.header-tabs-librances {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.input-div-librances {
    display: flex;
    justify-content: flex-end;
    width: 60%;
    align-items: center;
}

.input-search-librances {
    width: 55%;
    height: 35px;
    padding: 0.5% 1.5%;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px 0 rgb(236 236 236 / 50%);
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.content-alert {
    width: 100%;
    align-content: center;
    justify-content: center;
    margin-bottom: 8%;
}

.structure-alert {
    width: 100%;
    height: 34px;
    font-size: 14px;
    font-style: normal;
    text-align: center;
    line-height: normal;
    font-weight: normal;
    font-stretch: normal;
    padding: 7px 43px 8px;
    letter-spacing: normal;
    
}

.orange-alert {
    color: #f77d34;
    border: solid 1px #f7deb8;
    background-color: #f8ebd7;
}
.red-alert {
    color: #e4060d;
    border: solid 1px #f5c6cb;
    background-color: #f8d7da;
    border-radius: 3px;
    z-index: 9;
}
.content-alert-absolute{
    position: absolute;
    top: 185px;
    left: 50%;
    transform: translateX(-50%);
}

.green-alert {
    color: #5d5d5d;
    border: solid 1px #c3e6cb;
    background-color: #d4edda;
}