@import "../../../assets/icons.scss"; 
.searchForList-box-lists {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  position: absolute;
  width: 200%;
}
.label-style-simple-select {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #002c76;
  }
  .Pro-position-absolute {
    position: absolute;
  }
  .container-simple-select {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .Pro-style-simple-select {
    width: 100% !important;
    height: 24px;
    padding: 10px 30px 10px 11px !important;
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    background-color: #ffffff;
    -webkit-appearance: auto;
    color: #000000;
    font-size: 12px;
  }
    
  .optionsPro-content-style-simple-select { 
    display: inline-block;
    max-height: 187px;
    margin: 0px 0 0 0;
    padding: 4px 6px 14px 5px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(170, 192, 208, 0.5);
    background-color: #ffffff;
    overflow-y: auto;
    cursor: pointer;
  }
  .optionsPro-content-style-simple-select::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  #select-list input:disabled{
    border: solid 1px #d6d6d6;
    background-color: #eaeef2 !important;
    color: #b4b1b1 !important;
  }
  .optionsPro-content-style-simple-select::-webkit-scrollbar:vertical {
    width: 11px;
  }
  
  
  
  .optionsPro-content-style-simple-select::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  
  
  
  .optionsPro-content-style-simple-select::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }
  
  input#simpleSelectInput:placeholder-shown {
    background-image: none;
  }
  
  input#simpleSelectInput:disabled {
    border: solid 1px #d6d6d6;
    background-color: #eaeef2 !important;
    color: #b4b1b1 !important;
  }
  input#simpleSelectInput::placeholder {
    padding-left: 0%;
    color: #bcbfc2;
    font-size: 12px;
  }
  .smallPro-simple-select {
    padding: 0% 18%;
  }

  #simpleSelectInput::placeholder {
    color: #bfbcbc !important;
    font-size: 16px !important;
  }
  .Pro-simple-select-custom-border {
    border-color: #002c76 !important;
  }

  .optionsPro-style-simple-select {
    width: 100%;
    height: 43px;
    border-radius: 4px;
    cursor: pointer;
    padding: 0px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .force-hover, .optionsPro-style-simple-select:hover{
    background-color: #ecf5ff;
    font-weight: 600;
  }

  .drag-option-box {
    display: flex;
    align-items: center;
    padding: 0 4px 2px 5px;
    border-radius: 3px;
    font-size: 14px;
    margin: 0px 3px 5px 0px;
    border: solid 1px #d3d1d3;
    background-color: #fff;
    color: #4a4a4a;
  }
  .drag-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-height: 220px;
    overflow-y: auto;
  }
  .optionsPro-style-simple-select[disabled] {
    pointer-events: none;
    opacity: 0.7; 
  }
  .delete-option {
    margin-left: 6px;
    color: #002c76;
    font-weight: 600;
    cursor: pointer;
  }
  .arrow-select-icon {
    background: no-repeat $arrowSelectIcon;
    background-size: contain;
    position: absolute;
    cursor: pointer;
  }