@import "../../../assets//styles/fonts.scss";
@import "../../../assets/icons.scss";
@import "../../../assets/styles/tags.scss";

.historyFile-title {
    font-size: 20px;
    font-weight: 900;
    @extend .font-Roboto-Regular;
}
.historyFile-paragraph {
    font-size: 14px;
    font-weight: 400;
    color: #808080;
    @extend .font-Roboto-Regular;
}
.historyFile-box-refresh {
    cursor: pointer;
    position: absolute;
    right: 1px;
    top: 1px;
    display: flex;
    align-items: center;
    gap: 7px
}
.historyFile-refresh-button {
    width: 11px;
    height: 11px;
    background-image: $refresh-icon;
}
.historyFile-text-refresh {
    font-size: 14px;
    color: #0043A9;
    font-weight: 600;
    @extend .font-Roboto-Regular;
}

.historyFile-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
}
.historyFile-table tr {
    padding-top: 20px;
    height: 64px;
    display: flex;
}
.historyFile-table th, .historyFile-table td {
    padding-left: 15px;
    //width: 185px;
}
.historyFile-table th:nth-child(1), .historyFile-table td:nth-child(1) {
    width: 20%;
}
.historyFile-table th:nth-child(2), .historyFile-table td:nth-child(2) {
    width: 30%;
}
.historyFile-table th:nth-child(3), .historyFile-table td:nth-child(3) {
    width: 30%;
}
.historyFile-table th:nth-child(4), .historyFile-table td:nth-child(4) {
    width: 20%;
}
.historyFile-table tr td:nth-child(3) {
    padding-left:25px;
}

.historyFile-table tr:not(:last-child) td, .historyFile-table th {
    border-bottom: 1px solid #E6E6E6;
}

.historyFile-box-tag-info {
    @extend ._tag-info;
}
.historyFile-box-tag-success {
    cursor: pointer;
    @extend ._tag-success;
}
.historyFile-box-tag-error {
    @extend ._tag-error;
}
