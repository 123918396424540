@import "../../icons.scss";
@import "../colors.scss";
@import "../fonts.scss";

.check-green {
    width: 38px;
    height: 27px;
    background-position: center;
    background: no-repeat $check-trueGreen;
    border: solid 1.4px $white;

}
.toast-background {
    display: flex;
    align-items: center;
    width: 566px;
    height: 44px;
    padding: 9px 24px 8px 11px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(192, 192, 192, 0.5);
    border: solid 1px #cfcfcf;
    background-color: #f7f9fa;
    cursor: default;
}
p {
    margin-bottom: 0 !important;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.35px;
    color: #4a4a4a;
    width: 450px;
}
.toast-close {
    @extend .font-OpenSans-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.35px;
    text-align: center;
    color: #9b9b9b;
    cursor: pointer;
}