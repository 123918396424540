.back-link-font {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: start;
    color: #002c76;
    width: 20%;
    cursor: pointer;
}

.back-link-arrow {
    background: no-repeat url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='10' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpath id='a' d='M0 0h6v10H0z'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cmask id='b' fill='%23fff'%3e%3cuse xlink:href='%23a'/%3e%3c/mask%3e%3cpath d='M5.874.113a.463.463 0 0 0-.606 0L.126 4.728a.357.357 0 0 0 0 .544l5.142 4.615a.454.454 0 0 0 .303.113c.11 0 .22-.038.303-.113a.357.357 0 0 0 0-.544L1.034 5 5.875.657a.357.357 0 0 0 0-.544' stroke='%23002C76' stroke-width='.3' fill='%231C4385' mask='url(%23b)'/%3e%3c/g%3e%3c/svg%3e ");
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    width: 9px;
    height: 25px;
    margin-right: 2%;
    cursor: pointer;
}


