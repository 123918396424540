
.flex-center {
    display: flex;
    justify-content: center;
}

.flex-between {
    display: flex;
    justify-content: space-around;
    padding: 0% 5% 0% 5%;
}

.flex-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.display-none {
  display: none;
}

.width-box {
  width: 298px;
}

.padding-27 {
  padding: 0 27% 0 27%
}

.tittle {
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #545454;
}

.subtittle {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #002c76;
}

.div-subtittle {
    padding-top: 17px;
}

.label-restore {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #002c76;
}

.button-apply-enable {
  width: 191px;
  height: 37px;
  margin: 26px 0px 29px 0px;
  padding: 6px 24px 6px;
  border-radius: 16.5px;
  background-color: #002c76;
  
}

.button-apply-disable {
  width: 191px;
  height: 37px;
  margin: 26px 0px 29px 0px;
  padding: 6px 24px 7px;
  border-radius: 16.5px;
  background-color: #d4d4d4;
}

.text-button {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.priority-box {
    width: 298px;
    height: 37px;
    margin: 0% 0% 2% 0%;
    padding: 10px 27px 8px 17px; 
    display: flex;
    justify-content: space-between;  
}

.enable-box {
  background-color: #d7dce5; 
}

.disable-box {
  background-color: #d4d4d4; 
}

.priority-label {
   font-size: 14px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
}

.enable-label {
  color: #002c76;
}

.disable-label {
  color: #ffffff;
}

.droplabel {
    margin: 0 88.4px 0 0;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    color: #002c76;
    justify-content: space-between;
}

  .droplabel-enable {
    margin: 0 88.4px 0 0;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    color: #002c76;
    justify-content: space-between;
  }

  .droplabel-disable {
    margin: 0 88.4px 0 0;
    font-size: 16px;
    display: flex;
    color: #d6d6d6;
    justify-content: space-between;
  }

.dropbtn {
    width: 298px;
    height: 48px;
    padding: 12px 25.4px 14px 17px;
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    background-color: #ffffff;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    width: 298px;
  }
  
  .dropdown-content {
    display: block;
    position: absolute;
    background-color: #ffffff;
    width: 298px;
    box-shadow: 0 2px 4px 0 rgba(170, 192, 208, 0.5);
    z-index: 1;
    text-decoration: none;
    padding: 11px 7px 19px 8px;
    border-radius: 4px;
  }
  
  .dropdown-content a {
    color: black;
    padding: 8px 0 8px 11px;
    text-decoration: none !important;
    display: block;
  }
  
  .dropdown-content a:hover {
    margin: 0 0 8px;
    padding: 8px 0 8px 11px;
    border-radius: 4px;
    background-color: #ecf0f7;
    color: black;
    }

  .dropdown-selected {
    margin: 0 0 8px;
    padding: 8px 0 8px 11px;
    border-radius: 4px;
    background-color: #ecf0f7;
    color: black;
  }

  .cursor-pointier {
    cursor: pointer;
  }

  .alert-color {
    color: #6d7278 !important;
  }

  .icon-x-blue {
    background: no-repeat url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3e%3cpath fill='%23002C76' fill-rule='evenodd' stroke='%23002C76' stroke-width='.8' d='M1.034 1.271a.434.434 0 0 0 .1.475l4.252 4.253-4.253 4.254a.434.434 0 1 0 .614.614L6 6.613l4.253 4.254a.434.434 0 1 0 .614-.614L6.614 5.999l4.253-4.253a.434.434 0 1 0-.614-.614L6 5.385 1.747 1.132a.434.434 0 0 0-.713.14z'/%3e%3c/svg%3e ");
    background-position-y: center;
    background-size: contain;
    background-position-x: center;
    width: 15px;
    height: 16px;
  }

  .swal2-icon.swal2-info {
    border-color: #FF5733 !important;
    color: #FF5733 !important;
  }

  .alert {
    margin: 0 30% 1% 30%;
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
  }
  
  .paddin-modal {
      padding: 20px 13px 20px 13px;
  }
    
  .modal-center {
    display: flex;
    justify-content: center;
  }

  .modal-icon-info {
    background: no-repeat url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='57px' height='57px' viewBox='0 0 57 57' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eArtboard%3c/title%3e%3cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Group-5-Copy' transform='translate(1.000000, 1.000000)' stroke='%23002C76' stroke-width='1.7'%3e%3ccircle id='Oval' cx='27.5' cy='27.5' r='27.5'%3e%3c/circle%3e%3c/g%3e%3ccircle id='Oval-Copy' fill='%23002C76' cx='28.5' cy='13.5' r='2.5'%3e%3c/circle%3e%3crect id='Rectangle-Copy-2' fill='%23002C76' x='27' y='20' width='3' height='27'%3e%3c/rect%3e%3c/g%3e%3c/svg%3e");
    background-position-y: center;
    background-size: contain;
    background-position-x: center;
    width: 55px;
    height: 55px;
  }

  .modal-margin-title {
    margin: 3% 0 1% 0;
  }

  .modal-margin-subtitle {
    margin: 0 0 4% 0;
  }

  .modal-text {
    text-align: center;
    color: #545454;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 5%;
  }

  .modal-title-info {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #151515;
  }

  .modal-subtitle-info {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #151515;
  }

  .modal-button-blue {
    width: 111px;
    height: 33px;
    margin: 18px 0 0 9px;
    padding: 7px 25px 7px 27px;
    border-radius: 4px;
    background-color: #002c76;
  }

  .button-restore {
    width: 191px;
    height: 37px;
    margin: 26px 0px 29px 32px;
    padding: 6px 20px 7px;
    border-radius: 16.5px;
    border: solid 1px #002c76;
    background-color: #ffffff;
  }

  .modal-button-red {
    width: 108px;
    height: 33px;
    margin: 18px 0 0 9px;
    padding: 7px 18px 7px 21px;
    border-radius: 4px;
    background-color: #c61f01;
  }

  .modal-text-button {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #f9fafc;
  }

  .flex-content {
    display: flex;
    justify-content: center;
  }

  .icon-arrow-housing {
    background: no-repeat url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 129 129' enable-background='new 0 0 129 129' width='512px' height='512px' class=''%3e%3cg%3e%3cg%3e%3cpath d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' data-original='%23000000' class='active-path' data-old_color='%23002c76' fill='%23002c76'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e ");
    background-position-y: center;
    background-size: contain;
    width: 20px;
    height: 20px;
    float: left;
    margin-left: 266px;
    position: relative;
    bottom: 32px;
    cursor: pointer;
  }
  .select-style-priority {
    width: 298px !important;;
    height: 48px;
    margin: auto;
    padding: 8px 19px 10px 11px !important;
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    background-color: #ffffff;
    -webkit-appearance: auto;
  }
  .text-select-priority{
    font-size: 16px;
    font-weight: bold;
    color: #002c76;
  }
  .option-style {
    display: flex;
    width: 282px;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin: 4px 0px 2px 4px;
  }
  .option-select-color {
    background-color: #ecf5ff;
  }
  .span-option-style {
    margin-left: 4%;
    font-size: 16px;
    color: #383838;
  }
  .options-housing {
    display: inline-block;
    width: 298px;
    max-height: 187px;
    margin: -20px 0 0 6px;
    padding: 4px 6px 8px 4px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(170, 192, 208, 0.5);
    background-color: #ffffff;
    overflow-y: auto;
  }