@import "../../../assets/styles/fonts.scss";
@import '../../../assets/icons.scss';

.Insert_large_files_col {
    width: 100%;
    height: 50%;
    margin: 5% 0 0 0;
    padding: 0 41px 28px 30px;
    border-radius: 8px;
    border-style: solid;
    border-color: #E6E6E6;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @extend .font-Roboto-Regular;
}

.Insert_large_files_row {
    width: 100%;
    height: 50%;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.btn-largeFileDcs {
    padding: 8px 11px;
    font-weight: 600;
    width: 224px;
    font-size: 14px;
    border-radius: 100px;
    background-color: #002c76;
    border: none;
    color: #fff;
    margin: 15px 0 0 0;
}

.btn-largeFileRemoveDoc {
    background: no-repeat $trashIcon;
    background-size: contain;
    height: 20px;
    width: 24px;
    align-self: center;
    display: inline-block;
}