.td-component { 
    color: rgba(0, 0, 0, 0.85) !important;
    background-color: #ffffff !important;
    font-size: 12px !important;
}
.unset-align {
    vertical-align: unset !important;
}
.button-priority { 
    width: 77px !important;
    height: 31px !important;
    padding: 1px 68px 0px 12px !important;
    border-radius: 4px !important;
    border: solid 0.5px #002c76 !important;
    background-color: #002c76 !important;
    color: #ffffff !important;
    font-size: 14px !important;
}
.button-reassign { 
    width: 10px !important ;
    height: 25px !important;
    padding: 1px 75px 0px 8px !important;
    border-radius: 4px !important;
    border: solid 0.5px #002c76 !important;
    background-color: #002c76 !important;
    color: #ffffff !important;
    font-size: 14px !important; 
}
.button-view { 
    width: 5px !important ;
    height: 25px !important;
    padding: 1px 45px 0px 15px !important;
    border-radius: 4px !important;
    border: solid 0.5px #002c76 !important;
    background-color: #002c76 !important;
    color: #ffffff !important;
    font-size: 14px !important; 
    margin-left: 5px;
}
.button-resume { 
    width: 10px !important;
    height: 25px !important;
    padding: 0px 62px 0px 8px !important;
    border-radius: 4px !important;
    border: solid 0.5px #002c76 !important;
    background-color: #0040a8 !important;
    color: #ffffff !important;
    font-size: 14px !important;
}