@import '../../../assets/icons.scss';

.header-kardex-table {
    width: 100%;
    height: 57px;
    padding: 0% 3%;
    box-shadow: 0 -3px 10px 0 rgb(0 0 0 / 10%);
    background-color: rgba(0, 64, 168, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.check-icon-kardex-table {
    background-image: $icon-check-unchecked;
    background-repeat: no-repeat;
    background-position: 12px center;
    background-size: 21px;
    width: 39px;
    height: 39px;
}

.check-ok-icon-kardex-table {
    background-image: $icon-check-checked;
    background-repeat: no-repeat;
    background-position: 12px center;
    background-size: 21px;
    width: 39px;
    height: 39px;
}

.last-options-kardex-table {
    width: 9%;
    max-width: 50%;
    display: flex;
    justify-content: space-around;
}

.content-body-kardex-table {
    width: 100%;
    max-height: 43vh;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
    background: white;
    overflow-y: auto;
}

.row-kardex-table {
    display: flex;
    justify-content: space-between;
    padding: 0% 3%;
    align-items: center;
    height: 80px;
    font-size: 15px;
}

.invalid-kardex-input {
    border-color: #dc3545;
}

.row-kardex-table:hover {
    background-color: #eaeff4;
}

.row-kardex-table-white {
    background-color: #ffffff;
    color: #5c5c5c;
}

.button-add-kardex-table {
    width: 120px;
    height: 31px;
    padding: 7px 11px;
    border-radius: 4px;
    background-color: #002c76;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
}

.center-content-kardex-table {
    width: 90%;
    display: flex;
    align-items: center;
}

.edit-icon-kardex-table {
    background-image: $edit-icon;
    background-repeat: no-repeat;
    background-position: 12px center;
    background-size: 18px;
    width: 39px;
    height: 39px;
}
.edit-icon-kardex-table-disabled{
    opacity:0.4;
}


.line-separator {
    width: 100%;
    border: solid 0.5px #e4e4e4;
}

.header-tabs-kardex {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 8px 0 10px 0px;
}

.input-div-kardex {
    display: flex;
    justify-content: flex-end;
    width: 60%;
    align-items: center;
}

.input-search-kardex {
    text-indent: 20px;
    width: 55%;
    height: 35px;
    padding: 0.5% 1.5%;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px 0 rgb(236 236 236 / 50%);
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.input-search-kardex:placeholder-shown {
    background-image: $search-icon-placeholder;
    background-repeat: no-repeat;
    background-position: 12px center;
    background-size: 15px;
    display: flex;
    align-items: center;
}

.kardex-input:focus {
    box-shadow: none;
    border: 1px solid #ced4da;
    outline: none !important;
}

.no-checkbox-space {
    width: 10px;
}


.tooltip-kardex .tooltip-inner {
    max-width: 200px!important;
    padding: .25rem .5rem!important;
    color: #fff!important;
    text-align: center!important;
    background-color: #000!important;
    border-radius: .25rem;
    opacity: 0.7!important;
    height: auto!important;
    font-size: 12px;
}

.tooltip-kardex .arrow::before {
    opacity: 0.7!important;
    
    border-top-color: #000;
}