.flex {
    display: flex;
}
  
.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
  
.flex-justify-end {
  display: flex;
  justify-content: flex-end;
}
  
.flex-self-center {
  display: flex;
  align-self: center;
}

.report-order-section {
  height: 55vh;
  display: flex;
}
.report-order-box {
  padding: 2% 0% 2% 0%;
  width: 566px;
  position: absolute;
}
.report-order-box-label {
  align-items: flex-start;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #002c76;
  padding-bottom: 1%;
  cursor: pointer;
}
.report-order-box-middle {
  display: flex;
  justify-content: space-between;
  width: 48%;
}
.report-order-full-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.options-content-style-simple-select {
  width: inherit;
  max-height: 296px;
  padding: 4px 6px 14px 5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(170, 192, 208, 0.5);
  overflow-y: auto;
  position: absolute;
  z-index: 2;
  background-color: #ffff;
}
.options-style-simple-select {
  display: flex;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0px 2px 0;
}
.block-list-box {
  display: block;
  width: 100%;
}
.box-error {
  display: flex;
  justify-content: center;
}