body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background-color: #f7fbff !important;
  overflow: auto;
}

h1 a {
  font-size: 1.8rem;
}
h1 a.text-light:hover {
  color: white!important;
  text-decoration: none!important;
}
.nuevo-post{
  font-weight: bold;
  text-transform: uppercase;
}

.btn {
  font-weight: bold;
}
.form-group label,
.list-group-item {
  font-size: 1.4rem;
}

.navbar .btn {
  flex:1;
}
@media (min-width:768px){
    .navbar .btn {
      flex: 0 1 auto;
    }
}
.acciones {
  margin: 1rem 0;
}
@media (min-width:768px) {
  .acciones{
    margin: 0;
  }
}
.acciones > *{
  flex: 1;
}

.titel-modal {
  font-size: smaller;
  color: black;
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 75%;
  }
}

.w-2 {
  width: 2%;
}
.btn-cancel-Button{
  width: 120px;
  height: 40px;
  color: #002c76;
  background-color: white;
  border-color:  #002c76;
  border-radius: 5px;
  margin: 0px 0px 0px 10px;
}
.btn-Button-Close-Session{
  width: 120px;
  height: 40px;
  color: white;
  background-color: #c61f01;
  border-radius: 5px;
  margin: 0px 0px 0px 10px;
}
.btn-notCancel-Button{
  width: 120px;
  height: 40px;
  color: #ffff;
  background-color: #002c76;
  border-color:  #002c76;
  border-radius: 5px;
}