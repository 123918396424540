
@font-face {
    font-family: DTLArgoT;
    src: url(../fonts/DTLArgoT.otf);
}

@font-face {
    font-family: OpenSans-Semibold;
    src: url(../fonts/OpenSans-Semibold.ttf);
}

@font-face {
    font-family: OpenSans-Regular;
    src: url(../fonts/OpenSans-Regular.ttf);
}
@font-face {
    font-family: Roboto-Regular;
    src: url(../fonts/Roboto-Regular.ttf);
}

.font-DTLArgoT {
    font-family: 'DTLArgoT';
}

.font-OpenSans-Regular {
    font-family: 'OpenSans-Regular';
}

.font-Roboto-Regular {
    font-family: 'Roboto-Regular';
}

.font-OpenSans-Semibold {
    font-family: 'OpenSans-Semibold';
}
