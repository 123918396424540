.label-style {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #002c76;
}
.position-absolute {
  position: absolute;
}
.select-container {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.select-style {
    width: 312px !important;;
    height: 42px;
    padding: 8px 19px 10px 11px !important;
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    background-color: #ffffff;
    -webkit-appearance: auto;
  }
  .options-style {
    display: inline-block;
    width: 312px;
    max-height: 196px;
    margin: -20px 0 -35px 0;
    padding: 4px 6px 14px 5px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(170, 192, 208, 0.5);
    background-color: #ffffff;
    overflow-y: auto;
  }
  .options-style-second-level {
    width: 300px;
    max-height: 240px;
    margin: -8% 0 0 68%;
    padding: 4px 6px 14px 5px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(170, 192, 208, 0.5);
    background-color: #ffffff;
    overflow-y: auto;
  }
  .option-style {
    display: flex;
    width: 301px;
    height: 43px;
    border-radius: 4px;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0px 2px 0;
  }
  .option-style:hover {
    background-color: #ecf5ff;
  }
  .option-select-color {
    background-color: #ecf5ff;
  }

  .span-option-style {
    margin-left: 4%;
  }
  .icon-arrow-select {
    background: no-repeat url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 129 129' enable-background='new 0 0 129 129' width='512px' height='512px' class=''%3e%3cg%3e%3cg%3e%3cpath d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' data-original='%23000000' class='active-path' data-old_color='%23002c76' fill='%23002c76'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e ");
    background-position-y: center;
    background-size: contain;
    width: 20px;
    height: 20px;
    float: left;
    margin-left: 282px;
    position: relative;
    bottom: 29px;
    cursor: pointer;
  }
  .icon-arrow-select-submenu {
    background: no-repeat url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 129 129' enable-background='new 0 0 129 129' width='512px' height='512px' class=''%3e%3cg%3e%3cg%3e%3cpath d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' data-original='%23000000' class='active-path' data-old_color='%23002c76' fill='%23002c76'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e ");
    background-position-y: center;
    background-size: contain;
    width: 20px;
    height: 20px;
    float: left;
    margin-left: 275px;
    position: absolute;
    cursor: pointer;
  }
  .simple-div {
    width: 312px;
  }
  .button-disable {
    height: 42px;
    width: 142px;
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    background-color: #eaeef2;
    margin-left: 8px;
    cursor: default;
  }
  .text-button-disanle {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bbbec1;
  }
  .button-enable {
    height: 42px;
    width: 142px;
    border-radius: 4px;
    border: solid 1px #002c76;
    background-color: #002c76;
    margin-left: 8px;
  }
  .text-button-enable {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }