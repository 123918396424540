@import "src/assets/styles/margin.scss";
@import "src/assets/styles/colors.scss";
@import "src/assets/styles/fonts.scss";
@import "src/assets/icons.scss";

.content-docs-header {
    flex-direction: column;
    position: fixed;
    left:50%;
    transform: translateX(-50%);
    width: 715px;
}
.content-docs {
    position: absolute;
    @extend .mt-16;
    @extend .ml-20;
    z-index: -1;
}
.background-to-scroll {
    margin-top: -30px;
    left: 0;
    height: 244px;
    width: 100%;
    position: fixed;
    background-color: $grey-pale;
}
.text-name-file {
    @extend .font-OpenSans-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #9b9b9b;
}
.cancel-process {
    display: flex;
    align-items: center;
    margin: 3% 0% 0% 80%;
    cursor: pointer;
}
.cancel-icon {
    background: no-repeat $cancel;
    width: 10px;
    height: 10px;
}
.cancel-text {
    @extend .font-OpenSans-Regular;
    margin-left: 8px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #002c76;
}
.content-docs-list{
    position: absolute; 
    margin-top: 235px;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
}
.box-align-doc{
    display :flex;
    flex-direction: column;
    width: 100%;
}
.max-hg{
    height: 50px;
}
.content-upload-doc-exp {
    display: flex;
    flex-direction: column;
    padding-right: 3%;
    background-color: #f6f8fa;
    box-shadow: 0 2px 4px 0 rgba(170, 192, 208, 0.5);;
    border-radius: 4px;
    float: left;
    width: 710px;
    justify-content: center;
}
