.label-style-simple-select {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #002c76;
}
.position-absolute {
  position: absolute;
}
.container-simple-select {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.style-simple-select {
  width: 100% !important;
  height: 24px;
  padding: 10px 30px 10px 11px !important;
  border-radius: 4px;
  border: solid 1px #d6d6d6;
  background-color: #ffffff;
  -webkit-appearance: auto;
  color: #000000;
  font-size: 12px;
}
.style-div-simple-select {
  cursor: pointer;
  width: 100% !important;
  height: 24px;
  padding: 1% 3%;
  border-radius: 4px;
  border: solid 1px #d6d6d6;
  background-color: #ffffff;
  -webkit-appearance: auto;
  color: #000000;
  font-size: 12px;
}
.style-placeholder-simple-select {
  color:#4a4a4a !important;
}


.options-content-style-simple-select { 
  display: inline-block;
  width: 100%;
  max-height: 187px;
  margin: 0px 0 0 0;
  padding: 4px 6px 14px 5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(170, 192, 208, 0.5);
  background-color: #ffffff;
  overflow-y: auto;
}


.options-content-style-simple-select::-webkit-scrollbar:vertical {
  width: 100%;
}

.options-content-style-simple-select::-webkit-scrollbar:horizontal {
  height: 180px;
}

.options-content-style-simple-select::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}


.options-style-simple-select {
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0px 10px 0;
}
.options-style-simple-select:hover {
  background-color: #ecf5ff;
  font-weight: 600;
}
.option-color-simple-select {
  background-color: #ecf5ff;
}
.span-option-simple-select {
  margin-left: 4%;
  color: #4a4a4a;
  /*font-size: 12px;*/
}

.icon-arrow-simple-select-autocomplete {
  background: no-repeat url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 129 129' enable-background='new 0 0 129 129' width='512px' height='512px' class=''%3e%3cg%3e%3cg%3e%3cpath d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' data-original='%23000000' class='active-path' data-old_color='%23002c76' fill='%23002c76'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e ");
  background-position-y: center;
  background-size: contain;
  width: 2vw;
  height: 21px;
  float: left;
  position: relative;
  bottom: 26px;
  cursor: pointer;
  color: #ffffff;
}
.disabled-icon-arrow{
  filter:brightness(0) saturate(100%) invert(87%) sepia(0%) saturate(1782%) hue-rotate(319deg) brightness(85%) contrast(85%);  
}

.icon-arrow-simple-select {
  background: no-repeat no-repeat url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 129 129' enable-background='new 0 0 129 129' width='512px' height='512px' class=''%3e%3cg%3e%3cg%3e%3cpath d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' data-original='%23000000' class='active-path' data-old_color='%23002c76' fill='%23002c76'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e ");
  background-position-y: center;
  background-size: contain;
  width: 7%;
  height: 21px;
  float: right;
}

.simple-select-div {
  width: 98%;
  display: flex;
  justify-content: end;
}
input#simpleSelectInput:placeholder-shown {
  background-image: none;
}

input#simpleSelectInput:disabled {
  width: 100%;
  border: solid 1px #d6d6d6;
  background-color: #eaeef2 !important;
  color: #b4b1b1 !important;
}
input#simpleSelectInput::placeholder {
  width: 100%;
  padding-left: 0%;
  color: #bcbfc2;
  font-size: 12px;
}
.small-simple-select {
  padding: 0% 18%;
}

.small-arrow-simple-select {
  left: 87%;
}

.simply-select-desableg {
  background-color: #f4f4f4 !important;
  color: #d6d6d6 !important;
}

.cursor-default {
  cursor: default !important;
}
#simpleSelectInput::placeholder {
  color: #bfbcbc !important;
  font-size: 16px !important;
}
.simple-select-custom-border {
  border-color: #002c76 !important;
}