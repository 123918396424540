@import "./fonts.scss";

.btn-order {
    width: 142px;
    height: 56px;
    padding: 0 32px;
    border: none;
    border-radius: 100px;
    background-color: #002c76;
    @extend .font-OpenSans-Regular;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .btn-sends {
    width: 150px;
    height: 48px;
    padding: 0 32px;
    border-radius: 100px;
    border: solid 1px #0040a8;
    background-color: #fff;
    @extend .font-Roboto-Regular;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.2px;
    color: #0040a8;
  }
  .btn-order:disabled {
    opacity: 0.2;
    border: solid 2px rgba(0, 0, 0, 1);
    background-color: rgba(0, 0, 0, 0.3);
    color: #000;
  }
  