@import "../../icons.scss";

.icon-arrow-simple-select-autocomplete-order {
    background: no-repeat $icon-arrow;
    background-position-y: center;
    background-size: contain;
    margin : 14px 0px 0px -30px;;
    width: 20px;
    height: 20px;
    float: right;
    position: absolute;
    cursor: pointer;
  }
.options-style-simple-select:hover {
    background-color: #ecf5ff;
}
.options-content-style-simple-select-order {
  width: inherit;
  max-height: 296px;
  padding: 4px 6px 14px 5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(170, 192, 208, 0.5);
  overflow-y: auto;
  position: absolute;
  z-index: 2;
  background-color: #ffff;
}