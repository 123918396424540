

.react-datepicker-wrapper {
    width: 80%;
    margin-right: 1%;
}

.date-exception-disable {
    display: flex;
    margin-bottom: 2%;
}

.dropdown {
    z-index: 4;
}

.dropbtn-form {
    width: 100%;
}

.dropdown-content {
    width: 100%;
}

.react-datepicker-popper {
    z-index: 5;
}

.dropdown-form {
    z-index: 3;
    width: 90%;
}

input::placeholder {
    padding-left: 0%;
  }

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #7ad468;
    border-color: #7ad468;
    font-weight: bold;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle), .btn-group>.btn:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-weight: bold;
}

.btn-outline-success:hover {
    font-weight: bold;
    color: #fff;
    background-color: #7ad468;
    border-color: #7ad468;
}

.btn-group {
    width: 90%;
}

.btn-primary {
    background-color: #002c76 !important;
    border-color: #002c76 !important;
}

input:placeholder-shown {
    background-image: none;
    display: flex;
}

.ans-exceptions {
    width: 80%;
    margin-right: 1%;
}

.label {
    width: 100%;
    font-weight: 500 !important;
}

.flex-center-disbursement {
    display: flex;
    justify-content: center;
    padding: 0% 5% 0% 5%;
}

.pointer {
    cursor: pointer;
}

.card-content {
    padding: 2%;
}

.card {
    margin-top: 2%;
}
