@import "./colors.scss";

.report-order-input {
    height: 46px;
    padding: 11px 20px 12px 14px;
    width: inherit;
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    background-color: #fff;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #383838;
    outline: none;
}
.report-order-input:invalid {
    border: solid 1px $dark-red;
}
.report-order-input::placeholder {
    color: #d6d6d6;
}
.expedition-input {
    margin-left: 3px;
    width: 106px;
    height: 28px;
    padding: 7.5px 6px;
    border-radius: 2px;
    border: solid 0.5px #979797;
    background-color: #fff;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #4d4d4d;
    outline: none;
    &:disabled {
        background-color:  #e9ebed;
        border: solid 0.5px #979797;
        color: #bebebe;
    }
}
.expedition-input::placeholder {
    color: #c3c4c4;
}
.button-doc-disable {
    border: solid 1.5px #dddfe0 !important;
    background-color: #e9ebed !important;
    color: #babcbd !important;
}
.image-button-doc-disable {
    opacity: 0.3;
}
.expedition-input-error {
    border: solid 1px $dark-red;
}
.expedition-input-disable {
    background-color:  #e9ebed;
    border: solid 0.5px #979797;
    color: #bebebe;

}