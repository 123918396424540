@import '../colors.scss';
@import '../../icons.scss';
@import '../fonts.scss';

._order-sends_container {
    display: block;
    margin-top: 3vh;
    padding: 0px !important;
    @extend .font-OpenSans-Regular 
}

._order-sends_container h2 {
    color: #000a12;
}

._order-sends_grouping-table {
    position: relative;
}

._order-sends_box-tab {
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    top: -8px;
    width: 16%;
    height: 71px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 3px 0 rgba(236, 236, 236, 0.5);
    background-color: #f6f8fa;
}

._order-sends_box-tab::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    bottom: 0px;
    background-color: #fdc130;
}

._order-sends-tittle {
    font-size: 20px;
    font-weight: bold;
    color: $bank-blue;
}

._order-sends-count {
    margin-left: 10%;
    border: solid 1px $bank-blue;
    font-size: 14px;
    border-radius: 50%;
    height: 23px;
    padding: 0px 5px;
    font-weight: bold;
    color: #002c76;
}

._order-sends_space {
    margin-left: 11%;
    margin-bottom: 6vh;
}

._order-sends_update {
    display: flex;
    align-items: center;
    z-index: 15;
    position: absolute;
    top: 85px;
    right: 50px;
    font-size: 12px;
    font-weight: 600;
    color: $bank-blue;
    cursor: pointer;

}

._oder_sends-refresh-icon {
    margin-left: 6px;
    width: 11px;
    height: 11px;
    background-image: $refresh-icon;
}
._order-sends-documents-preview{
    background: $sheet-comment;
    height: 18px;
    width: 16px;
}
.cursor-pointer{
    cursor:pointer;
}
.line-separator-document-disbursement{
    width: 1px;
    height: 13px;
    margin: 4.5px 20px 1.5px 20px;
    border: solid 1px #979797;
}
.disbursement-status-indicator{
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 4px;
}
.disbursement-status-indicator.status-send{
    background-color: #32c5ff;
}
.disbursement-status-indicator.status-approved{
    background-color: #7ad468;
}
.disbursement-status-indicator.status-open{
    background-color: #fdc130;
}

.disbursement-returned{
    color: #e02020;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 4px;
}
.disbursement-returned-icon{
    background: $eye-red;
    height: 8px;
    width: 16px;
}
.custom-swal-content{ 
    overflow: auto!important;
    border-radius: 0.1875em!important;
    max-height: 218px!important;
    height: 218px!important;
    width: 550px!important;
    font-size: 14px!important;
    margin-left: auto!important;
    margin-right: auto!important;
    margin-bottom: 25px!important;
    line-height: 1.43!important;
    letter-spacing: -0.35px!important;
    border:1px solid #d9d9d9!important;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.06), 0 0 0 3px transparent!important;
    color: #4a4a4a!important;
    text-align: initial!important; 
    padding: 10px!important;
}
.no-border{
    border: none!important;
}
.custom-swal-header{
    padding: 14px 13px 19px 13px!important;
    font-size: 19px!important;
    font-weight: 600!important;
    font-stretch: normal!important;
    font-style: normal!important;
    line-height: 1.44!important;
    letter-spacing: normal!important;
    text-align: center!important;
    color: #002c76!important; 
}
.custom-swal-close-btn{

    width: 29px!important;
    height: 29px!important;
    margin: 10px 10px 0px 0px!important;
    background-color: #c4d4e3!important;
    color: #0040a8!important;
    border-radius: 50%!important;
    position: absolute!important;
}

@media (max-width:1334px){
    ._order-sends-tittle{
        font-size: 16px;
    }
    ._order-sends_update{
        top: 90px;
        right: 20px;
    }
}