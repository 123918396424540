.input-large-dynamic-modal {
    width: 100%;
    height: 24px;
    padding: 0% 3%;
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    background-color: #ffffff;
    font-size: 12px;
}
.info-label-dynamic-modal {
    margin: 0px 79px;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #151515;
}
.input-small-dynamic-modal {
    width: 100%;
    height: 24px;
    padding: 0% 5%;
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    background-color: #ffffff;
    font-size: 12px;
}

.alert_input{
    width: 100%;
    height: 24px;
    padding: 0% 5%;
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    background-color: #ffffff;
    border-color: #eb4e53;
    font-size: 12px;
}

input#name:placeholder-shown {
    background-image: none;
}

input#code:placeholder-shown {
    background-image: none;
}

input#firstUser:placeholder-shown {
    background-image: none;
}

input#secondUser:placeholder-shown {
    background-image: none;
}

input#name::placeholder {
    padding-left: 0%;
    color: #bcbfc2;
    font-size: 12px;
}

input#code::placeholder {
    padding-left: 0%;
    color: #bcbfc2;
    font-size: 12px;
}

input#firstUser::placeholder {
    padding-left: 0%;
    color: #bcbfc2;
    font-size: 12px;
}

input#secondUser::placeholder {
    padding-left: 0%;
    color: #bcbfc2;
    font-size: 12px;
}

.span-dynamic-modal {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #002c76;
    margin-bottom: 2%;
}

.modal-header {
    border-bottom: none !important;
}

.modal-footer {
    border-top: none !important;
}

.title-dynamic-modal {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0041a1;
}

.subtitle-dynamic-modal {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #29292a;
}

.modal-footer {
    justify-content: center !important;
    margin: 0% 10% !important;
}

.button-dynamic-modal {
    width: 30%;
    height: 26px;
    padding: 0.3% 0%;
    border-radius: 4px;
    background-color: #002c76;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    border: none;
    margin: 0% 5% 0% 5%;
}
.button-dynamic-modal-large {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #002c76;
    color: white;
    border: 1px solid transparent;
    padding: 0.175rem 1.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.button-dynamic-modal-large:disabled {
    color: #bbbec1;
    border-radius: 4px;
    background-color: #eaeef2;
    border: solid 1px #dee1e5;
    border: none;
}

.button-dynamic-modal:disabled {
    color: #bbbec1;
    width: 30%;
    height: 26px;
    padding: 0.3% 0%;
    border-radius: 4px;
    background-color: #eaeef2;
    border: solid 1px #dee1e5;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    border: none;
    margin: 0% 5% 0% 5%;
}

.button-disabled-dynamic-modal {
    color: #bbbec1;
    width: 30%;
    height: 26px;
    padding: 0.3% 0%;
    border-radius: 4px;
    background-color: #eaeef2;
    border: solid 1px #dee1e5;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    border: none;
}

.modal-dialog {
    max-width: 532px !important;
}

.modal-dynamic-select-padding {
    padding: 0% 0% 7% 0%;
}

.modal-content {
    padding: 2% 3% !important;
}

.footer-dynamic-modal {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-content: center;
    align-items: center;
    padding-top: 5%;
}

.small-width-dynamic-modal {
    width: 85% !important;
    margin-top: 8%;
}

.select-dynamic-modal {
    height: 24px;
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    background-color: #ffffff;
    height: 24px;
    padding: 0% 3%;
    width: 100%;
    color: #d3d1d3;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.icon-info-dynamic-modal {
    background: no-repeat url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='57px' height='57px' viewBox='0 0 57 57' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eArtboard%3c/title%3e%3cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Group-5' transform='translate(1.000000, 1.000000)' stroke='%23002C76' stroke-width='1.7'%3e%3ccircle id='Oval' cx='27.5' cy='27.5' r='27.5'%3e%3c/circle%3e%3c/g%3e%3ccircle id='Oval' fill='%23002C76' cx='28.5' cy='13.5' r='2.5'%3e%3c/circle%3e%3crect id='Rectangle' fill='%23002C76' x='27' y='20' width='3' height='27'%3e%3c/rect%3e%3c/g%3e%3c/svg%3e");
    background-position-y: center;
    background-size: contain;
    background-position-x: center;
    height: 55px;
    margin: 1% 0% 5% 0%;
}
.input-container-user{
    display: grid;
    grid-template-columns: auto auto;
    width: 110%;
}

.Usuario-de-red-incor{
    font-family: OpenSans;
    font-size: 14px;
    color: #e4060d;
  }
  
