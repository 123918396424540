@import '../../../assets/styles/fonts.css';
@import '../../../assets/icons.scss';

.container-pdf-viewr{
    position: absolute;
    top: 350px;
    left: 600px;
    user-select: none;
}

.btns-trndocs-prev-next-page{
    position: absolute;
    display: flex;
    justify-content: space-around;
    top: 300px;
    left: 600px;
    width: 600px;
    margin: auto;
    align-items: center;
}

.row-inputs-trndocs {
    display: flex;
    justify-content: space-between;
    margin: auto;
    align-items: center;
    margin-top: 30px;
}

.rw-lst-keypairs{
    display: flex;
    width: 300px;
    margin-left: 30px;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    height: 55px;
}

.trn-dcs-input {
    display: flex;
    justify-content: space-evenly;
    margin: auto;
  }

.rw-btn-delkeypairs{
    height: 30px;
}

.blue-btn-trndcs{
    padding: 8px 11px;
    font-weight: 600;
    width: 100px;
    font-size: 14px;
    border-radius: 4px;
    background-color: #002c76;
    border: none;
    color: #fff;
}

.white-btn-trndcs {
    font-size: 12px;
    font-weight: 700;
    padding: 10px 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align:center;
    color: #002c76;
    border: solid 1px #002c76;
    background-color: #fff;
    width: 100px;
}

.trndcs-especial-btn{
    width: 120px;
    height: 40px;
    border-radius: 6px;
    border: solid 1px #0040a8;
    background-color: #fff;
    background-position-y: center;
    background-position-x: 19px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    cursor: pointer;

}

.icon-thrash-trn-docs{
    width: 30px;
    height: 30px;
    background: no-repeat $trashIcon;
    margin: 5% -10% 0% 0%;
    padding: 0% 0% 0% 0%;
  }

  .icon-prevpage-trn-docs{
    align-self: center;
    width: 24px;
    height: 24px;
    background: no-repeat $previousPage;
    filter: invert(13%) sepia(97%) saturate(3794%) hue-rotate(211deg) brightness(85%) contrast(105%);
  }

  .icon-nextpage-trn-docs{
    align-self: center;
    width: 24px;
    height: 24px;
    background: no-repeat $nextPage;
    filter: invert(13%) sepia(97%) saturate(3794%) hue-rotate(211deg) brightness(85%) contrast(105%);
  }

  .trndcs-text-especial-btn {
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.2px;
    color: #0040a8;
    display: inline-block;
  }

.button-upload-trn-dcs{
    color: #002c76;
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
    border: #002c76;
    border-style: solid;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
}