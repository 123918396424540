@import "../../../assets/icons.scss";
@import '../../../assets/styles/fonts.css';

.header-mr-6 {
    margin-right: 6rem;
}
.header-margins-user {
    margin: 8px 6px 0px 6px;
}
.header-div-flex {
    display: flex;
    align-items: center;
    justify-content: end;
}
.header-user-icon {
    width: 13px;
    height: 13px;
    object-fit: contain;
    background-image: $userIcon;
}
.header-close-sesion-icon {
    margin-right: -4px;
    width: 13px;
    height: 14px;
    object-fit: contain;
    background-image: $closeSesionIcon;
}
.header-close-sesion {
    font-size: 13px;
    color: #fff;
}
.header-name {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: normal;
    color: #fff;
}
.header-username{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    max-width: 280px;
}
@media (max-width:1410px){
    .header-username{
        max-width: 190px;
    }
}
@media (max-width:1300px){
    .header-username{
        max-width: 170px;
    }
}
@media (max-width:1200px){
    .header-username{
        max-width: 160px;
    }
}
@media (max-width:1100px){
    .header-username{
        max-width: 120px;
    }
}
@media (max-width:1000px){
    .header-username{
        max-width: 86px;
    }
}
@media (max-width:900px){
    .header-username{
        max-width: 60px;
    }
}


@media (max-width:1250px){
    .header-font{
        margin-top: 5px;
    }
    .div-logout{
        display: flex;
        align-items: center;
    }
    .upload-cases-btn{
        margin-top: 7px;
        padding: 0.25rem 0.5rem!important;
        font-size: .875rem!important;
        line-height: 1.5!important;
        border-radius: 0.2rem!important;
    }
}

.header-col-custom {
    flex: 0 0 52%;
    max-width: 63%;
}
.header-col-custom-2 {
    flex: auto;
    max-width: 23%;
}
.header-font {
    font-size: 1.8rem;
    cursor: pointer;
}

.tooltip-header .tooltip-inner {
    max-width: 200px!important;
    padding: .25rem .5rem!important;
    color: #fff!important;
    text-align: center!important;
    background-color: #000!important;
    border-radius: .25rem;
    opacity: 0.7!important;
    height: auto!important;
    font-size: 12px;
    border: 1px solid black!important;
}