@import "./fonts.scss";
._tag {
    display: inline;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    @extend .font-Roboto-Regular;
}
._tag-info {
    @extend ._tag;
    background-color: #E3F3FF;
    color: #004C97;
    border: 1px solid #004C97;
}
._tag-success {
    @extend ._tag;
    background-color: #EBFFE0;
    color: #2B770F;
    border: 1px solid #2B770F;
}
._tag-error {
    @extend ._tag;
    background-color: #FFE9E8;
    color: #9F211B;
    border: 1px solid #9F211B;
}