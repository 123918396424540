@import '../../../../assets/icons.scss';
@import '../../../../assets/styles/fonts.css';

section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 22vw;
    gap: 3vh;
    margin-top: 16vh;
}

.tittle-report {
    color: #545454;
    font-size: 22px;
    font-weight: 600;
}

.sub-tittle-report {
    font-size: 14px;
    color: #002c76;
}
.row-form {
    display: flex;
    width: 100%;
    gap: 19px;
}

.col-date {
    width: 78%;
}
.date-input {
    width: 100%;
}

.col-date .leader-arrow-select {
    right: 13px;
}

.search-button {
    align-self: flex-end;
    width: 92px;
    height: 42px;
    padding: 11px 22px 12px 21px;
    border-radius: 4px;
    background-color: #002c76;
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: white;
}

.col-date .react-datepicker-wrapper {
    width: 100%;
}

.upload-icon {
    position: relative;
    display: inline-block;
    background-image: $uploadIcon;
    background-repeat: no-repeat;
    width: 19px;
    height: 19px;
    top: 4px;
    left: 4px;
    background-size: contain;
}

.download-btn {
    width: 178px;
    height: 42px;
    border-radius: 21px;
    background-color: #002c76;
    font-size: 14px;
    font-weight: 600;
    color: white;
    border: none;
}
button:disabled {
    background-color: #eaeef2;
    color: #bbbec1;

    .upload-icon {
        filter:brightness(0) saturate(100%) invert(87%) sepia(0%) saturate(1782%) hue-rotate(319deg) brightness(85%) contrast(85%);
    }
}