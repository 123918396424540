.dragger-drop {
  padding: .5rem;
  display: block;
  width: 298px;
  height: 100%;
  grid-template-columns: repeat(auto-fill, 300px);
  background-color: #ffffff;
}

.dragger-group {
  border-radius: 5px;
  padding: .5rem;
  overflow-y: auto;
  max-height: 280px;
}
.dragger-label {
  margin: 4% 0% 0% 3%;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #002c76;
}
.dragger-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 19px;
  margin: 2px 0 3px 15px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #625c5c;
  height: 24px;
  margin: 13px 12px 7px 0;
  padding: 0 0 0 0;
}
.dragger-item:focus {
  cursor: move;
}
.in-priority {
  background-color: #e3fee4;
}
.not-priority {
  background-color: #ecf0f7;
}
.dragger-item:not(:last-of-type) {
  margin-bottom: .5rem;
}
.dragger-item * {  
  margin: 0;
  font-size: 1.2rem;
}
.group-title {
  text-align: left;
  margin-bottom: .5rem;
  font-size: 1.5rem;
}
.current {
  display: none;
}

.line {
  width: 95%;
  height: 15px;
  margin: 5% 0% -6% 0;
  color: #979797;
}

.not-line {
  display: none;
}

.cursor-move {
  cursor: move;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon-draw {
  background: no-repeat url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='19px' height='24px' viewBox='0 0 19 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eIcono_drag%3c/title%3e%3cg id='Icono_drag' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3crect id='Rectangle' fill='%23CFD5E0' x='0' y='0' width='19' height='24'%3e%3c/rect%3e%3cpath d='M9.48025978,5.39364212 L11.3017644,8.93490717 L9.90614453,8.93453516 L9.90588925,11.7008858 L12.8298893,11.7008858 L12.8301354,10.3192945 L16.4731445,12.0899271 L12.8301354,13.8605596 L12.8298893,12.5238858 L9.90588925,12.5238858 L9.90614453,15.3105352 L11.1967847,15.3110829 L9.51903735,18.9985352 L7.84128995,15.3110829 L9.05914453,15.3105352 L9.05888925,12.5238858 L6.24914453,12.5238858 L6.24977375,13.735898 L2.47314453,12.0888869 L6.24977375,10.4418757 L6.24914453,11.7008858 L9.05888925,11.7008858 L9.05914453,8.93453516 L7.6587552,8.93490717 L9.48025978,5.39364212 Z' id='Combined-Shape' fill='%23979797'%3e%3c/path%3e%3c/g%3e%3c/svg%3e");
  background-position-y: center;
  background-size: contain;
  width: 19px;
  height: 23px;
  float: left;
  margin-right: 3%;
}

.absolute {
  display: block;
  width: 298px;
  height: 100%;
}

.relative {
  position: relative;
  left: 47%;
  top: -7px;
  margin-bottom: -15px;
}

.icon-plus {
  background: no-repeat  url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eIcono_mas%3c/title%3e%3cg id='Icono_mas' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3ctext id='-' font-family='OpenSans, Open Sans' font-size='18' font-weight='normal' fill='%23FFFFFF'%3e%3ctspan x='5.66796875' y='12.9633789'%3e-%3c/tspan%3e%3c/text%3e%3cg id='Group' transform='translate(0.006104, -0.709473)'%3e%3ccircle id='Oval' fill='%23002C76' cx='8' cy='8.71234131' r='8'%3e%3c/circle%3e%3cpath d='M8.68774414,12.394043 L8.68774414,9.26074219 L8.68774414,9.26074219 L11.7023926,9.26074219 C12.0373231,9.26074219 12.3088379,8.98922737 12.3088379,8.65429688 C12.3088379,8.31936638 12.0373231,8.04785156 11.7023926,8.04785156 L8.68774414,8.04785156 L8.68774414,8.04785156 L8.68774414,4.89697266 C8.68774414,4.55961513 8.41426183,4.28613281 8.0769043,4.28613281 C7.73954677,4.28613281 7.46606445,4.55961513 7.46606445,4.89697266 L7.46606445,8.04785156 L7.46606445,8.04785156 L4.46899414,8.04785156 C4.13406364,8.04785156 3.86254883,8.31936638 3.86254883,8.65429688 C3.86254883,8.98922737 4.13406364,9.26074219 4.46899414,9.26074219 L7.46606445,9.26074219 L7.46606445,9.26074219 L7.46606445,12.394043 C7.46606445,12.7314005 7.73954677,13.0048828 8.0769043,13.0048828 C8.41426183,13.0048828 8.68774414,12.7314005 8.68774414,12.394043 Z' id='+' fill='%23FFFFFF' fill-rule='nonzero'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
  background-position-y: center;
  background-size: contain;
  background-position-x: center;
  width: 15px;
  height: 16px;
  z-index: 4;
}

.icon-less {
  background: no-repeat url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eIcono_menos%3c/title%3e%3cg id='Icono_menos' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3ctext id='-' font-family='OpenSans, Open Sans' font-size='18' font-weight='normal' fill='%23FFFFFF'%3e%3ctspan x='5.66796875' y='12.9633789'%3e-%3c/tspan%3e%3c/text%3e%3ccircle id='Oval' fill='%23002C76' cx='8.00610352' cy='8.00286865' r='8'%3e%3c/circle%3e%3cpath d='M11.1425781,7.95578175 C11.1425781,7.58687279 10.8435183,7.287813 10.4746094,7.287813 L5.68567708,7.287813 C5.31676813,7.287813 5.01770833,7.58687279 5.01770833,7.95578175 C5.01770833,8.3246907 5.31676813,8.6237505 5.68567708,8.6237505 L10.4746094,8.6237505 C10.8435183,8.6237505 11.1425781,8.3246907 11.1425781,7.95578175 Z' id='-' fill='%23FFFFFF' fill-rule='nonzero'%3e%3c/path%3e%3c/g%3e%3c/svg%3e");
  background-position-y: center;
  background-size: contain;
  background-position-x: center;
  width: 15px;
  height: 16px;
}
