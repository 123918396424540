.flex-evenly {
    display: flex;
    justify-content: space-evenly;
    padding: 0% 5% 0% 5%;
}

.pointer {
    cursor: pointer;
}

.prioritizeImageDisbursemet {
    background: no-repeat url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='276px' height='77px' viewBox='0 0 276 77' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eGroup 23%3c/title%3e%3cg id='004_Desembolso_centralizado_LIDER' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Group-23'%3e%3cpolygon id='Fill-47' fill='%23D7DCE5' points='0 24 276 24 276 0 0 0'%3e%3c/polygon%3e%3cg id='Group-6' transform='translate(0.000000, 5.000000)' font-size='12'%3e%3ctext id='Línea' font-family='OpenSans-Semibold, Open Sans' font-weight='500' fill='%23002C76'%3e%3ctspan x='17' y='13'%3eLínea%3c/tspan%3e%3c/text%3e%3ctext id='Producto' font-family='OpenSans-Semibold, Open Sans' font-weight='500' fill='%23002C76'%3e%3ctspan x='89' y='13'%3eProducto%3c/tspan%3e%3c/text%3e%3ctext id='1-Consumo-2-Comercia' font-family='OpenSans, Open Sans' font-weight='normal' fill='%234B4B4B'%3e%3ctspan x='0' y='34'%3e1 Consumo%3c/tspan%3e%3ctspan x='0' y='51'%3e2 Comercial%3c/tspan%3e%3c/text%3e%3ctext id='Sust.-Pasivos-CSM-(o' font-family='OpenSans, Open Sans' font-weight='normal' fill='%234B4B4B'%3e%3ctspan x='89' y='34'%3eSust. Pasivos CSM (orden llegada)%3c/tspan%3e%3ctspan x='89' y='51'%3eCon FNG (orden llegada)%3c/tspan%3e%3c/text%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    background-size: contain !important;
    height: 110px !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
    background-position-x: center !important;
    background-position-y: center !important;
    margin: '5% 0% 5% 0%' !important;
}

.flex-center-disbursement {
    display: flex;
    justify-content: center;
    padding: 0% 5% 0% 5%;
}