@import '../../../assets/styles/fonts.css';
@import '../../../assets/icons.scss';
@import '../LeaderProductivityComponent/LeaderProdStyles.scss';

.blue-font{
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0041a1;
}
.prd-sumze-full-box {
    max-width: 400px;
    margin: 0px 0px 0px 25px;
}
.prd-sumze-tittle {
    margin: 29px 0px 0px 3px;
    font-size: 16px;
    font-weight: 600;
}
.prd-sumze-sub-tittle {
    margin: 13.3px 0px 11.7px 3px;
    font-size: 14px;
    font-weight: normal;
}
.prd-sumze-resume {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 75px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(189, 189, 189, 0.5);
    background-color: #fff;

}
.prd-sumze-resume-box {
    @extend .prd-sumze-resume;
    width: 68%;
}
.prd-sumze-resume-box-full-width {
    @extend .prd-sumze-resume;
    width: 100%;
}

.prd-sumze-resume-cases-box {
    @extend .prd-sumze-resume;
    width: 30%;
    flex-direction: column;
    position: relative;
    padding-top: 13px;
}
.prd-sumze-resume-cases-detail {
    @extend .prd-sumze-resume;
    position: absolute;
    width: 130%;
    top: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding-left: 10%;
    right: 0%;
}
.arrow {
    position: unset;
    @extend .leader-arrow-select;
    outline: none;
    caret-color: transparent;
    width: 14px;
}
.prd-sumze-general-box {
    margin-top: 7px;
    width: 400px;
    height: 319px;
    padding: 0px 0 0 0;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(189, 189, 189, 0.5);
    background-color: #fff;
    overflow: scroll;
}
.prd-resume-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
} 

.flex-row{
    display: flex;
    justify-content: space-between;
}
.flex-row-title{
    display: flex;
    justify-content: flex-start;
}
.prd-cardAd{
    height: 17px;
    margin: 2px 0px 13px 10px;
    padding: 1px 3px 2px;
    border-radius: 4px;

    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}
.prd-novedad{
    border: solid 1px #f7b934;
    background-color: #fcf3de;
    color: #e39e08;
}
.prd-automatic{
    border: solid 1px #4885c2;
    background-color: #e3f3ff;
    color: #4885c2;
}
.prd-massive-new {
    border: solid 1px #8e7bfd;
    background-color: #e3f3ff;
    color: #8e7bfd;
}
.prd-conflict{
    border: solid 1px red;
    background-color: #ffe9e8;
    color: red;
}
.prd-sumze-card{
    border-bottom: solid .5px rgba(189, 189, 189, 0.5);
    width: 100%;
    height: 78.5px;
    padding-left: 30px;
    padding-right: 10px;
}

.Time-without-registration{
    width: 100%;
    height: 8px;
    padding-left: 30px;
    padding-right: 10px;
    background-color:#0041a1;
    display: flex;
    align-items: center;
    border: solid 3px #e1e1e1;

}

.circle{  
    height: 20px;
    width: 20px;
    background-color: #0041a1;
    border-radius: 100%;    
    margin: 0px 0px 0px -33px;
    display: flex;
    text-align: center;
	justify-content: center;
	align-items: center;
    font-family: sans-serif;
	color: white;
    position: relative;
    cursor: help;
}


.circle span {
    background: rgba(148, 194, 245, 0.9); 
    border-radius: 5px;
    color: #0a0c0d;
    font-size: small;
    display: none; 
    text-align: center;
    width: 120px ;
    height: 20px;
    position: absolute;
    left: 25px;
}

.circle:hover span {
    display: inline; 
}

.card-btn{
    height: 18px;
    width: 18px;
    margin-bottom: 5px;
    cursor: pointer;
}

.disabled-background{
    background-color:#eaeef2;
    cursor: not-allowed;
}

.disabled-background > div :not(.prd-novedad, .prd-conflict, .prd-automatic){
    cursor: not-allowed;
    filter: brightness(0) saturate(100%) invert(87%) sepia(0%) saturate(1782%) hue-rotate(319deg) brightness(85%) contrast(85%);
}
.disabled-background-buttons{
    background-color:#eaeef2;
    cursor: not-allowed;
    filter: brightness(0) saturate(100%) invert(87%) sepia(0%) saturate(1782%) hue-rotate(319deg) brightness(85%) contrast(85%);
}

.disabled-novedad{
    background-color:#eaeef2;
    color: #b4b1b1;
    border-color: #b4b1b1;
    cursor: not-allowed;
}
.delete-card-btn{
    margin-right: 31px;
    background: no-repeat $trashIcon;
}

.edit-card-btn{
    margin-right: 15px;
    background: $editIcon;
}

.empty-cards{
    background: no-repeat $scheduleIcon;
    background-position-x: center;
    background-size: 30px;
    margin-top: 132px;
    padding-top: 32px;
    text-align: center;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #b4b1b1;
    filter:brightness(0) saturate(100%) invert(87%) sepia(0%) saturate(1782%) hue-rotate(319deg) brightness(85%) contrast(85%);
}
.container-hours{
    display: flex;
}
.cart-Hours{
    margin: 0px 3px 0px 3px;
}
.cart-conflict-Hours{
    color: #f23a31;
    margin: 0px 3px 0px 3px;
}
.kardex-card{
    border-bottom: solid .5px rgba(189, 189, 189, 0.5);
    width: 100%;
    height: 90.5px;
    padding-left: 16px;
    padding-right: 10px;
    gap:2px;
}

.flex-row-kardex{
    display: flex;
    justify-content: center;
    margin: 6% 0% 0% 3%;
}
.container-flex-row{
    display: flex;
    flex-direction: row;
    justify-content: row;
    margin: 0% 0% 0% 5%;
}

.edit-card-btn-kardex{
    margin-right: 15px;
    background: $editIcon;
    margin: 0% 0% 0% 0%;
}