.font-22 {
    font-size: 22px;
}
.font-16 {
    font-size: 16px;
}

.d-flex {
    display: flex;
}
.header-table-component {
    justify-content: space-between;
    background-color: #d7dce5;
    padding: 3px 20px 4px 20px;
    height: 37px;
    align-items: center;
}
.header-font-component {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #002c76;
}
.weight-font-component {
    font-weight: normal;
}
.column-titles-table-component {
    background-color: #edf1f8;
}
.column-title-width{
    width: 30%;
}
.column-info-table-component {
    background-color: #ffffff;
}
.column-info-width{
    width: 70%;
}
.body-info-table-component {
    display: flex;
    align-content: center;
    justify-content: space-evenly;
}
.body-info-title-table-component {
    display: flex;
    align-content: center;
    justify-content: space-evenly;
}
.margin-info-title-table-component {
    margin: 14px 0px 7px 0px;
}
.margin-info-body-table-component {
    margin: 7px 0px;
}
.padding-process-title-column {
    align-items: center;
    display: flex;
    padding: 9px 0 0px 20px;
    height: 40px;
}
.font-title-process-column {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}
.padding-process-column {
    align-items: center;
    display: flex;
    padding: 0px 0 0px 20px;
}
.font-general-body {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    text-align: center;
}
.cell-table-component {
    width: 70px;
}
.footer-table-component {
    justify-content: space-between;
    background-color: #d7dce5;
    height: 37px;
    align-items: center;
}
.footer-general-font {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}
.footer-general-margin {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}
.logoLoading {
    background: no-repeat url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='margin: auto; background: none; display: block; shape-rendering: auto;' width='200px' height='200px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3e%3ccircle cx='50' cy='50' r='30' stroke-width='10' stroke='%23002c76' stroke-dasharray='47.12388980384689 47.12388980384689' fill='none' stroke-linecap='round'%3e%3canimateTransform attributeName='transform' type='rotate' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0 50 50;360 50 50'%3e%3c/animateTransform%3e%3c/circle%3e%3c/svg%3e");
    background-size: contain;
    height: 24px;
    width: 29px;
}