.options-reasign {
    color: #002c76;
    cursor: pointer;
    font-size: 16px;
}

.case-label-reasign {
  width: 100%;
  height: 33px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #004aaf;   
}

.case-process-reasign {
  width: 160px;
  height: 36px;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #29292a;
}

.alert-color-reasign {
  color: #e4060d;
}
.alert-input-user {
  border-color: #e4060d;
}

.alert-color-success-reasign {
  color: #737373;
  border-color: #c3e6cb;
  background-color: #d4edda !important;
}