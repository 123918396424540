@import "../../icons.scss";
@import "../fonts.scss";

.preview-main-container {
  margin-top: 5%;
}

.preview-header {
  padding: 1% 0;
}

.preview-metadata-container {
  display: inline-block;
  width: 60%;
}

.preview-names-container {
  display: flex;
  flex-direction: column;
}

.preview-document-type {
  @extend .font-OpenSans-Semibold;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
}

.preview-document-name {
  @extend .font-OpenSans-Semibold;
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 600;
}

.preview-close-container {
  display: inline-block;
  float: right;
  width: 2%;
}

.preview-close-icon {
  background: no-repeat $close-blue;
  background-position-x: right;
  cursor: pointer;
  height: 20px;
  width: 100%;
}

.preview-body {
  background: #ffffff;
  box-shadow: 0 -1px 6px 0 rgba(136, 136, 136, 0.5);
  height: 65vh !important;
  overflow-y: scroll;
  overflow-x: scroll;
}

.preview-footer {
  padding: 0.5% 0;
}

.preview-delete-container {
  float: right;
  text-align: right;
  width: 20%;
}

.preview-delete-icon {
  background: no-repeat $trash;
  background-position-y: center;
  cursor: pointer;
}

.preview-delete-icon-label {
  @extend .font-OpenSans-Semibold;
  color: #002c76;
  cursor: pointer;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  line-height: 2.18;
  letter-spacing: normal;
  margin-left: 5%;
}

.yarl__icon {
  width: var(--yarl__icon_size);
  height: var(--yarl__icon_size);
  color: var(--yarl__icon_color, #002c76);
}

.yarl__button:disabled {
  background-color:  transparent;
  cursor: default;
}