.report-order-margin-content-row {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 55px;

}
.report-order-row {
    margin: 4% 0% 4% 0%;
}
// .span-option-simple-select {
//     margin-left: 10px;
//     color: #4a4a4a;
//     font-size: 18px;
//   }
.ml-20{
    margin-left: 20%;
}
.mt-3{
    margin-top: 3%;
}
.ml-8{
    margin-left: 8px;
}
.mt-16{
    margin-top: 16;
}
.ml-60{
    margin-left: 60px;
}
.ml-212 {
    margin-left: 212px;
}