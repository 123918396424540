@import '../../icons.scss';

._table_search-icon {
    background-image: $search-icon;
    width: 32px;
    height: 14px;
    background-position: center;
    background-repeat: no-repeat;
}

.table-custom {
    background-color:  #fff ;
    border-radius: 4px ;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) ;
}
div[class^="Component-horizontalScrollContainer"]{
    @extend .table-custom;
}

div[class^="jss"]{
    @extend .table-custom;
}