@import '../../../assets/styles/fonts.css';
@import '../../../assets/icons.scss';

.ml--10 {
    margin-left: -10px;
}

.ml--17 {
    margin-left: -17px;
}

.wt-middle {
    width: 45%;
}
.gap-26 {
    margin-left: 26px;
}

.disable-box {
    background-color: #eaeef2;
    color: #b4b1b1;
}

.container-max-box {
    display: flex;
    flex-direction: row;
}

.container-reported-news {
    display: flex;
    flex-direction: column;
    margin: 53px 0px 0px 30px;
}

.leaderProd-button-box {
    width: 142px;
    height: 42px;
    font-size: 14px;
    padding: 8px 23px;
    margin: 0px 0px 0px 0px;
    border-radius: 4px;
    background-color: #002c76;
    border: none;
    color: #fff;
}

.leaderProd-button-box:disabled {
    border: solid 1px #d6d6d6;
    @extend .disable-box;
}

.leaderProd-button-salve {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.leaderProd-button-cancel {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0px 373px 0px 0px;
    border: solid 1px #002c76;
    color: #002c76;
    background-color: #fff;
}

.leaderProd-form-box {
    width: 666px;
    height: 340px;
    padding: 17px 26px 21px 29px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(189, 189, 189, 0.5);
    background-color: #fff;
}

.newsReport-form-box {
    width: 515px;
    height: 118px;
    padding: 17px 26px 21px 29px;
    margin: 0px 0px 0px 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(189, 189, 189, 0.5);
    background-color: #fff;
}

.newsReport-button-search {
    margin: 10% 0% 0% 35%;
    width: 140px;
    height: 42px;
    padding: 10px 23px;
    border-radius: 4px;
    background-color: #002c76;
    color: #ffff;
    border: none;
}

.leaderProd-column-big {
    width: 291px;
    right: 26px;
    bottom: 10px;
    z-index: 1;
    &-added-margin {
        @extend .leaderProd-column-big;
        bottom: 22px;
    }
}

.newsReport-button-search:disabled {
    border: solid 1px #d6d6d6;
    @extend .disable-box;
}

.newsReport-search-manager {
    width: 100%;
    height: 19px;
    margin: 0px 0px 0 0px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f77d34;

}

.leaderProd-full-box {
    max-width: 666px;
    margin-top: 19px;
    margin: 10px 0px 0px 65px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mg-prodbutton-hide-content {
    width: 666px;
    margin-top: -61px;
    margin-left: 28px;
}

.mg-prodbutton-massive-hide-content {
    width: 666px;
    margin-top: -87px;
    margin-left: 28px;
}

.leaderProd-tittle {
    margin: 19px 0px 0px 29px;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0041a1;
}

.leaderProd-sub-tittle {
    margin: 15.3px 0px 11.7px 29px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0041a1;
}

.leaderProd-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.leaderProd-column {
    width: 48%;
}

.leaderProd-search-user {
    width: 100%;

}

.leaderProd-header-col {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #002c76;
    margin-bottom: 5px;
}

.leaderProd-select-time {
    width: 142px;
    height: 40px;
    padding: 9px 17px 9px 13px;
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    background-color: #fff;
}

.leaderProd-input-box-option {
    width: 293px;
    height: 40px;
    padding: 10px 15px 11px 15px;
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    background-color: #fff;
}

.leaderProd-input-box-big {
    height: 60px;
    resize: none;
    &-bigger {
        height: 132px;
        resize: none;    
    }
}

.leaderProd-input-box {
    width: 294px;
    height: 40px;
    padding: 10px 15px 11px 15px;
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    background-color: #fff;
}

.leaderProd-input-box-search-user {
    width: 210%;
    height: 40px;
    padding: 10px 15px 11px 15px;
    border-radius: 3px;
    border: solid 1px #d3d1d3;
    background-color: #fff;

}

.leaderProd-input-content::placeholder {
    color: #bfbcbc;
}

.error-focus {
    border: solid 1px #e4060d;
}

.warning-focus {
    border: solid 1px #f77d34;
}

.normal-focus:focus {
    border: solid 1px #0041a1
}

.leaderProd-input-box:disabled {
    @extend .disable-box;
}

.leaderProd-input-content {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
}

.leaderProd-input-content::placeholder {
    color: #bfbcbc;
}

.flex-column {
    display: flex;
    flex-direction: column;
}
.leaderProd-flex-simple-row {
    display: flex;
    flex-direction: row;
}
.leader-dateInput {
    display: flex;
    align-items: center;
}
.col-leader-2 {
    flex: 0 0 16.67%;
    max-width: 20%;
}

.leaderProd-flex-col {
    display: flex;
    flex-direction: column;
    width: 123px;
    // margin: 40px 28px 0px 0px;
}

.ttoast {
    position: absolute;
    justify-content: space-evenly;
    font-size: 13px;
    width: 401px;
    height: 34px;
    border-radius: 3px;
    margin-top: -5px
}

.ttoast-success {
    border: solid 1px #c3e6cb;
    background-color: #d4edda;
    color: #5d5d5d;
}

.ttoast-error {
    color: #d5353a;
}

.ttoast-warning {
    color: #f77d34;
}

.leader-arrow-select {
    background: no-repeat no-repeat $arrowSelectIcon;
    background-position-y: center;
    background-size: contain;
    width: 15px;
    height: 15px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
}

.kardex-arrow-right {
    right: 18px;
}

.leader-arrow-left {
    right: -25px;
}

.leader-arrow-right {
    right: -33px;
}

.leader-sumze-general-box {
    margin-top: 15px;
    width: 400px;
    height: 340px;
    padding: 0px 0 0 0;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(189, 189, 189, 0.5);
    background-color: #fff;
    overflow: scroll;
}

.reported-news-tittle {
    margin: 19px 0px 0px 0px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0041a1;
}

.not-register-empty-cards {
    background: no-repeat $scheduleIcon;
    background-position-x: center;
    background-size: 30px;
    margin-top: 132px;
    padding-top: 32px;
    text-align: center;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #b4b1b1;
    filter: brightness(0) saturate(100%) invert(87%) sepia(0%) saturate(1782%) hue-rotate(319deg) brightness(85%) contrast(85%);
}

.group-name {
    margin: 14px 12px 0 12px;
    font-size: 16px;
    font-weight: 600;
    color: #002c76;
}

.btn-edit-group {
    margin-top: 12px;
    background: $editIcon;
    height: 18px;
    width: 18px;
    cursor: pointer;
}

.group-container-header {
    display: grid;
    grid-template-columns: repeat(3, auto);
}

.group-members-count-container {
    display: grid;
}

.badge-group-members-count {
    color: #fff;
    width: 42px;
    border-radius: 3px;
    background-color: #002c76;
}

.group-members-count-header {
    font-size: 10px;
    color: #002c76;
}

.leader-dateInput input:disabled {
    @extend .disable-box;
}

.group-settings_container {
    flex-direction: column;
    @extend .container-max-box;
}

@mixin box-dynamic($width, $height) {
    width: $width;
    height: $height;
}

.group-settings_form-box {
    min-height: 38vh;
    @include box-dynamic(50vw, 100%);
    @extend .flex-column;
    @extend .leaderProd-form-box
}

.group-settings_input-box {
    @include box-dynamic(100%, 40px);
    @extend .leaderProd-input-box-option;
    @extend.leaderProd-input-content;
}

.group-settings_input-box:focus {
    border-color: #002c76;
}

.group-settings_delete-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.group-settings_separator {
    width: 100%;
    margin: 5px 0px 0px 0px;
}

.group-settings_submit-button {
    margin-top: auto;
    padding: 8px 11px;
    @extend .leaderProd-button-box;
    font-weight: 600;
}

.group-settings_cursor-pointer {
    cursor: pointer;
}

.edit-kardex-col{
    width: 42%;
    
}
.leaderProd_number-case-col {
    width: 48%;
    margin-left: auto
}
.leaderProd_number-case-input {
    @include box-dynamic(100%, 40px);
    @extend .leaderProd-input-box-option;
    @extend.leaderProd-input-content;
}

.kardex-date-parent > .react-datepicker-wrapper{
    width: 100%;
}