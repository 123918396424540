@import '../../../../assets/styles/fonts.css';
@import '../../../../assets/icons.scss';

.container-documents-box{
    display: flex;
    flex-direction: column;
}
.container-elements-search{
    display: flex;
    flex-direction: row;
}

.input-documents{
    width: 46%;
    margin: 0px 0px 10px 0px;
}


.container-documents-view{
    width: 100%;
    height: 509px;
    margin: -110px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    
}
.documents-header-col{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #002c76;
    margin-bottom: 1px;
}
.container-download-document{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}
.container-download-documents{
    display: flex;
    flex-direction: row;
    margin: 3% 0px 0px 90px;
    cursor: pointer;
}

.download-document-btn{
    height: 18px;
    width: 18px;
    margin-bottom: 5px;
    cursor: pointer;
}
.close-view-document-btn{
    height: 18px;
    width: 18px;
    margin-bottom: 5px;
    cursor: pointer;
}

.download-document-icon{
    height: 12px;
    width: 12px;
    margin: 2px 9px 0px 9px;
    background: $uploadIconBlue;
}

.close-document-disbursemen{
    height: 20px;
    width: 20px;
    background: $closeWindows;
    cursor: pointer;
}

.container-download-document-sm{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}
.container-download-document-sm > .download-document-icon{
    margin: 2px 9px 0px 0px;
}


@media (max-width:1545px){
    .container-download-documents{
        display: none;
    }
}
@media (min-width:1545px){
    .container-download-document-sm{
        display: none;
    }
}