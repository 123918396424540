
@font-face {
    font-family: OpenSans-Regular;
    src: url(../fonts/OpenSans-Regular.ttf);
}
@font-face {
    font-family: OpenSans-Semibold;
    src: url(../fonts/OpenSans-Semibold.ttf);
}
@font-face {
    font-family: OpenSans-Bold;
    src: url(../fonts/OpenSans-Bold.ttf);
}

@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto-Regular.ttf);
}

.font-OpenSans-Regular {
    font-family: 'OpenSans-Regular';
}
.font-OpenSans-Semibold {
    font-family: 'OpenSans-Semibold';
}
.font-OpenSans-Bold {
    font-family: 'OpenSans-Bold';
}

.font-20{
    font-size: 20px;
}

.font-14{
    font-size: 14px;
}

.font-13{
    font-size: 13px;
}

.font-12{
    font-size: 12px;
}

.font-orange{
    color: #ff6800;
}
.font-green{
    color:#7ad468
}