@import '../../../assets/icons.scss';

/*
 * Report Case css File
*/

.title-disbursement-leader{
  margin-bottom: 3rem!important;
  margin-top: 6rem !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545454;
}

.cards-container-leader {
  display: flex;
  margin-top: 4%;
  justify-content: center;
  
}

.card-default-leader {
  width: 130%;
  height: 100%;
  padding: 46px 32px 47px;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffff !important;
  color: #333;
  text-align: center;
  cursor: pointer;
  
}
.card-default-leader:hover {
  background-color: #0040a8 !important;
  color: #ffff !important;
  cursor: pointer;
}

.card-text {
  margin-top: 22%;
  font-size: 18px;
}
//
.card-report-case-leader {
  background: no-repeat $onePeopleIcon;
  background-color: #cccccc;
  background-position-x: center;
  background-position-y: 38%;
  margin: 0% 0% 0% -50%;
}

.card-manage-case-leader {
  background: no-repeat $masivePeople;
  background-position-x: center;
  background-position-y: 38%;
  margin: 0% 0% 0% 10%;
}

.card-disabled {
  width: 304px;
  height: 160px;
  padding: 44px 32px 43px;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #eaeef2;
  text-align: center;
}
.text-disabled {
  margin-top: 22%;
  font-size: 18px ;
  color: #cccccc;
}

.active-option:after {
  content: "";
  display: block;
  border-width: 0 0 1px;
  border-style: solid;
}

.content-option-administrative-news {
  display: flex;
  color: #002c76 !important;
}
.content-option-administrative-news :nth-child(1){
  gap: 10px;
}

.container-button{
  width: 70%;
  margin: 0% 0% -4% 22% ;
  padding: 0% 0% 0% 0% ;
}
.container-admin{
  float: left;
  margin-bottom: 8px;
}